import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { Row, Column } from 'simple-flexbox'

import IconSearch from '../../assets/icon-search'

import OutsideAlerter from '../../hooks/outside-click'

import SearchResults from './search-results'

const SearchBar = () => {
  const [ isFocused, setIsFocused ] = useState(false)
  const [ search, setSearch ] = useState('')

  return (
    <>
      <Row
        flexGrow={1}
        alignItems="center"
        style={{ margin: '0 40px 0 25px' }}
      >
        <Column>
          <IconSearch />
        </Column>
        <Column flexGrow={1}>
          <Input
            className="search-bar"
            placeholder="Search for endpoints or values"
            onFocus={() => setIsFocused(true)}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Column>
      </Row>
      {
        isFocused
          ? (
            <OutsideAlerter callback={() => setIsFocused(false)}>
              <SearchResults
                search={search.toLowerCase()}
                onSelect={() => {
                  setIsFocused(false)
                  setSearch('')
                }}
              />
            </OutsideAlerter>
          )
          : null
      }
    </>
  )
}

export default SearchBar
