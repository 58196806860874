import React, { Component } from 'react'
import EmailValidator from 'email-validator'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row
} from 'reactstrap'

import { AuthContext } from '../../context/auth-context'

class ForgotPW extends Component {
  constructor (props) {
    super(props)

    this.sendEmail = this.sendEmail.bind(this)

    this.state = {
      forgotPWError: '',
      email: '',
      requesting: false,
      resetEmailSent: false
    }
  }

  sendEmail (e) {
    if (e) {
      e.preventDefault()
    }

    const {
      email
    } = this.state

    const {
      forgotPW
    } = this.context

    if (email === '' || !EmailValidator.validate(email)) {

      return this.setState({
        forgotPWError: 'Please submit a valid email.'
      })
    }

    this.setState({
      requesting: true
    })

    forgotPW(email, forgotPWError => {
      if (forgotPWError) {
        this.setState({
          forgotPWError,
          requesting: false
        })

        return
      }

      this.setState({
        resetEmailSent: true,
        requesting: false
      })
    })
  }


  render () {
    const {
      email,
      requesting,
      forgotPWError,
      resetEmailSent
    } = this.state

    return (
      <div className="mt-7 flex-row align-items-center klavika-regular">

        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">

                <img
                  // eslint-disable-next-line
                  src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/spawn/api-logo-2023-full-black.png"
                  className="img-api-logo-sm"
                  alt=""
                />

                  <CardBody>
                    <Form>
                      <div>
                        <h1><b>Forgot Password</b></h1>
                        <p className="bg-120 lh-20">
                        Enter the email associated with your
                        account and we will email you with
                        further instructions.
                        </p>
                        {
                          forgotPWError
                        }
                        {
                          !resetEmailSent ?
                            (
                              <div>
                                <InputGroup className="mb-3">
                                  <Input
                                    type="text"
                                    placeholder="Email"
                                    autoComplete="email"
                                    id="emailInput"
                                    value={email}
                                    onChange={
                                      e => {
                                        this.setState({ email: e.target.value })
                                      }
                                    }
                                  />
                                </InputGroup>
                                <Row>
                                  <Col>
                                    {
                                      !requesting ?
                                        (
                                          <Button
                                            color="dark"
                                            id="sendEmail"
                                            block
                                            className="px-4"
                                            onClick={this.sendEmail}
                                          >
                                          <b>SEND EMAIL</b>
                                          </Button>
                                        )
                                        :
                                        (
                                          <Button
                                            color="dark"
                                            className="px-4"
                                            disabled
                                          >
                                          <b>Sending...</b>
                                          </Button>
                                        )
                                    }
                                  </Col>
                                </Row>
                              </div>
                            ) :
                            (
                              <div>
                                <p>
                                We have sent an email with instructions on
                                how to reset your password.
                                </p>
                              </div>
                            )
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

ForgotPW.contextType = AuthContext

export default ForgotPW
