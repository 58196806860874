import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Highlight from 'react-highlight'

const DocResponseModal = ({ response, isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      style={{
        maxWidth: '1000px',
        width: '70%',
        borderRadius: '10px',
        backgroundColor: '#29323F',
        color: '#fff',
        border: 'none'
      }}
    >
      <ModalHeader toggle={toggle} className="bg-130 pad-20 pad-top-15 pad-bottom-15 klavika-regular"><b>API Response</b></ModalHeader>
      <ModalBody
        style={{
          overflowY: 'scroll',
          borderRadius: '8px',
          height: '80vh',
          backgroundColor: '#29323F',
          border: 'none'
        }}
      >
        <Highlight
          className="json"
          style={{ maxHeight: '240px', background: '#1d1f21' }}
        >
          {response}
        </Highlight>
      </ModalBody>
    </Modal>
  )
}

export default DocResponseModal
