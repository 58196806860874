import React, { useState } from 'react'
import { string } from 'prop-types'
import { Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import SearchBar from '../search/search-bar'

import { useAccount } from '../../context/account-context'

import { toTitleCase } from '../../helpers/general'

const styles = StyleSheet.create({
  avatar: {
    height: 35,
    width: 35,
    borderRadius: 50,
    marginLeft: 14,
    border: '1px solid #DFE0EB'
  },
  container: {
    height: 40,
    marginTop: '20px'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  name: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: '#ffffff',
    textAlign: 'right',
    letterSpacing: 0.2,
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  nameWrap: {
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  searchbar: {
    fontStyle: 'normal',
    color: '#f4f4f4',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 18,
    lineHeight: '25px',
    padding: '5px',
    width: '60%',
    borderRadius: '70px',
    letterSpacing: 0.3,
    background: '#29323F',
    '@media (max-width: 468px)': {
      fontSize: 20
    },
    '@media (max-width: 768px)': {
      marginLeft: 36,
      width: '100%'
    }
  },
  iconStyles: {
    cursor: 'pointer',
    marginLeft: 25,
    '@media (max-width: 768px)': {
      marginLeft: 12
    }
  }
})

const Header = props => {
  const { title, ...otherProps } = props
  const history = useHistory()
  const { account } = useAccount()

  const [ modalIsOpen, toggleModalOpen ] = useState(false)

  const toggle = () => toggleModalOpen(!modalIsOpen)

  return (
    <Row
      className={css(styles.container)}
      vertical="center"
      horizontal="space-between"
      {...otherProps}
    >
      <span className={css(styles.searchbar)}>
        <SearchBar />
      </span>
      <Row vertical="center" className={css(styles.nameWrap)}>
        <span
          className={css(styles.name, styles.cursorPointer)}
          onClick={() => history.push('/account')}
          onKeyUp={() => {}}
        >
          { account.name ? toTitleCase(account.name) : 'Account' }
        </span>
      </Row>
    </Row>
  )
}

Header.propTypes = {
  title: string
}

export default Header
