import React from 'react'

import { useHistory } from 'react-router-dom'

import {
  Table
} from 'reactstrap'

import {
  capitalizeSports,
  formatKeyWords
} from '../../../helpers/general'

const EndpointItem = ({ endpoint, onSelect, search }) => (
  <tr
    className="pad-10 search-item"
    onClick={() => onSelect(endpoint.link)}
  >
    <td className="width-2" />
    <td className="width-98">
      <div
        dangerouslySetInnerHTML={
          { __html: formatKeyWords(capitalizeSports(endpoint.title)) }
        }
      />
      <div className="sm-85">
        <span className="">{endpoint.route}</span>
      </div>
    </td>
  </tr>
)

const Docs = ({ docs, onSelect, count, search }) => {
  const history = useHistory()

  return (
    <div>
      <div
        className="pad-10 bg-130 text-center klavika-regular"
      >
        <b>Documentation</b>
      </div>

      <Table size="sm" borderless>
        <tbody>
          {
            docs.slice(0, count).map(d => (
              <EndpointItem
                key={d.route}
                endpoint={d}
                onSelect={l => {
                  history.push(`/docs/${ l }`)
                  onSelect()
                }}
                search={search}
              />
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default Docs
