import React from 'react'

import {
  Button
} from 'reactstrap'

import CheckIcon from '@material-ui/icons/Check'


import {
  ThreeDots
} from 'react-loader-spinner'

const SubmitButton = ({ role, inviteUser, inviteSuccess, loading }) => {
  return (
    <div>
      <Button
        className="sport-endpoint-button mar-top-50 width-100 bg-170 klavika-regular"
        onClick={inviteUser}
      >
        {
          inviteSuccess && !loading
            ? <CheckIcon className="green" />
            : null
        }

        {
          !inviteSuccess && !loading
            ? (
              <b>
              INVITE
                {role === 'developer' ? ' DEVELOPER' : ' MEMBER'}
              </b>
            )
            : null
        }

        {
          !inviteSuccess && loading
            ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#ffffff"
                  visible
                  ariaLabel="three-dots-loading"
                />
              </div>
            )
            : null
        }
      </Button>
    </div>
  )
}



export default SubmitButton
