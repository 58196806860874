import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { Row, Column } from 'simple-flexbox'
import AspectRatioRoundedIcon from '@material-ui/icons/AspectRatioRounded'

import {
  Button,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap'

import ResponseModal from './response-modal'

const JSONViewer = ({ response }) => {
  const [ modalIsOpen, toggleModalOpen ] = useState(false)

  const toggle = () => toggleModalOpen(!modalIsOpen)

  return (
    <>
      <ResponseModal
        isOpen={modalIsOpen}
        toggle={toggle}
        response={response}
      />
      <Card
        className="card-json-viewer pad-0"
      >
        <CardTitle
          style={{ borderBottom: '1px solid #8894a8' }}
          className="pad-10"
        >
          <Row>
            <Column flexGrow={11} className="bg-130 pad-left-10 klavika-regular">
              <b>Response</b>
            </Column>
            <Column flexGrow={1} className="text-right pad-right-10">
              <AspectRatioRoundedIcon onClick={toggle} style={{cursor: 'pointer'}} />
            </Column>
          </Row>
        </CardTitle>
        <CardBody className="pad-top-0 code-pink" style={{ height: '400px', overflowY: 'scroll' }}>
          {
            response ?
              (
                <ReactJson
                  src={response}
                  name={false}
                  collapseStringsAfterLength={20}
                  groupArraysAfterLength={3}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  theme={{
                    base00: '#29323F',
                    base01: '#29323F',
                    base02: '#29323F',
                    base03: '#29323F',
                    base04: '#fff',
                    base05: '#fff',
                    base06: '#fff',
                    base07: '#fff',
                    base08: '#fff',
                    base09: '#0ec8f8',
                    base0A: '#fff',
                    base0B: '#0ec8f8',
                    base0C: '#fff',
                    base0D: '#fff',
                    base0E: '#E83E85',
                    base0F: '#11ce00'
                  }}
                />
              )
              : '// Make a request to see a response!'
          }
        </CardBody>
      </Card>
    </>

  )
}

export default JSONViewer
