import React, { useState } from 'react'


const EndpointSection = ({ endpoints, showEndpoints, toggleShowEndpoints }) => {
  if (endpoints.length === 0) return null

  return (
    <span
      className="pad-5 pad-left-10 pad-right-10 rounded pointer mar-right-10 sm-90"
      style={{ border: '1px solid #CDDCEF', color: '#CDDCEF' }}
      onClick={toggleShowEndpoints}
    >
      Endpoints
      {' '}
      <span className={`enhanced-sports enhanced-sports-arrow-${!showEndpoints ? 'down' : 'up'} sm-80`} />
    </span>
  )
}

export default EndpointSection
