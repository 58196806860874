import React from 'react'
import { Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'

import { useHistory } from 'react-router-dom'

const styles = StyleSheet.create({
  container: {
    marginRight: 32,
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Muli',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 19,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#FFFFFF',
    marginLeft: 60
  }
})

const SidebarLogo = () => {
  const history = useHistory()

  return (
    <Row
      className={css(styles.container)}
      horizontal="center"
      vertical="center"
      onClick={() => history.push('/')}
    >
      <span className={css(styles.title)}>
        <img
          src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/api-logo-2023-white.png"
          width="auto"
          height="35"
          alt="Swish"
          className="navbar-brand-full pt-3"
        />
      </span>
    </Row>
  )
}

export default SidebarLogo
