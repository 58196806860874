import React, { useState } from 'react'
import { Column, Row } from 'simple-flexbox'

import {
  Button
} from 'reactstrap'

const AccasContainer = () => {
  const [ currentAccaIdx, setCurrentAccaIdx ] = useState(0)

  const accas = [
    { desc: 'Patrick Mahomes and Josh Allen both 300+ Passing Yards', odds: '+200' },
    { desc: 'Tyreek Hill and Justin Jefferson both 100+ Receiving Yards', odds: '+280' },
    { desc: 'Christian McCaffrey and Derrick Henry both 100+ Rushing Yards', odds: '+230' }
  ]


  setTimeout(() => {
    setCurrentAccaIdx(prevValue => {
      let a = prevValue + 1

      if (a > 2) a = 0

      return a
    })
  }, 3000)

  return (
    <Row
      horizontal="spaced"
      className="pad-30 pad-bottom-0 mar-top-50 light-blue-back rounded-docs"
    >

      <Column flex="1" className="mar-top-0 text-left pad-10">
        <div
          className="new-markets-tag bg-130 klavika-regular"
        >
          <b>NEW MARKETS</b>
        </div>

        <b className="bg-250 pad-top-25">NFL In-Play Pre-Built Parlays</b>
        <div
          className="light-blue bg-150 mar-bottom-15 mar-top-5 lh-25 pad-right-20"
        >
          Live SGPs for every snap.  Generated from our Bet Request engine for easy one-tap parlays.
        </div>

        <div className="pad-right-30">


          <Row wrap horizontal="start" className="mar-top-0 mar-bottom-0">

            <Column flex="1" className="mar-0 mar-top-0 mar-bottom-0">
              <Button
                className="sport-endpoint-button pad-20 mar-top-10 mar-bottom-10 bg-130"
                style={{ marginRight: 'auto' }}
                href="/docs/nfl-accumulators-inplay"
              >
                <b className="pad-left-20 pad-right-20">EXPLORE DOCS</b>
              </Button>
            </Column>
          </Row>
        </div>
      </Column>
      <Column flex="1" className="pad-10 mar-left-10">
        <div className="card dark-back bord-top-yellow">
          <Row>
            <Column flex="1" className="bord-bottom-grey">
              <Row>
                <Column flex="3">
                  <div className="text-left bg-180 pad-15">
                    <img
                      src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nfl.png"
                      width="auto"
                      height="30"
                      alt="Swish"
                      className="mar-top-neg-5 pad-right-5"
                    />
                    <b>IN-PLAY SGPs</b>
                  </div>
                </Column>

                <Column flex="1">
                  <span className="text-right bg-180 pad-20">
                    <div className="live-indicator mar-top-5">
                      <span className="live-dot animated flash slower infinite" />
                      <span className="sm-70 pad-left-5">LIVE</span>
                    </div>
                  </span>
                </Column>
              </Row>
            </Column>
          </Row>

          <Row className="animated fadeIn">

            <Column flex="1" className="pad-10">
              <div className="klavika-regular bg-160 lh-25 text-left pad-20">
                <b>{ accas[ currentAccaIdx ].desc }</b>
              </div>

              <Row>
                <Column flex="1" className="mar-top-10">
                  <Button
                    className="btn-in-play text-center mar-20 mar-top-5 bg-150"
                    href="/docs/nfl-accumulators-inplay"
                  >
                    <span className="font-weight-bold">
                      YES { accas[ currentAccaIdx ].odds }
                    </span>
                  </Button>
                </Column>
              </Row>
            </Column>
          </Row>
        </div>
      </Column>
    </Row>
  )
}

export default AccasContainer
