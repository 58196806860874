import React, { useState } from 'react'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

const SportDropdown = ({ sport, setSport }) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="sport-endpoint-dropdown" caret>
        {sport ? sport.toUpperCase() : 'Sport'}
      </DropdownToggle>
      <DropdownMenu className="sport-endpoint-menu" style={{ backgroundColor: 'rgb(41, 50, 63)', border: '0px'}}>
        <DropdownItem className="swish-dropdown-item pad-left-10" onClick={() => setSport('nba')}>
        <img
        src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nba.png"
        width="auto"
        height="20"
        alt="Swish"
        className="mar-top-neg-3 pad-right-5"
      />
        NBA
        </DropdownItem>
        <DropdownItem className="swish-dropdown-item pad-left-10" onClick={() => setSport('nfl')}>
        <img
        src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nfl.png"
        width="auto"
        height="20"
        alt="Swish"
        className="mar-top-neg-3 pad-right-5"
      />
      NFL
        </DropdownItem>
        <DropdownItem className="swish-dropdown-item pad-left-10" onClick={() => setSport('mlb')}>
        <img
        src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/mlb.png"
        width="auto"
        height="20"
        alt="Swish"
        className="mar-top-neg-3 pad-right-5"
      />
      MLB
        </DropdownItem>
        <DropdownItem className="swish-dropdown-item pad-left-10" onClick={() => setSport('nhl')}>
        <img
        src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nhl.png"
        width="auto"
        height="20"
        alt="Swish"
        className="mar-top-neg-3 pad-right-5"
      />
      NHL
        </DropdownItem>
        <DropdownItem className="swish-dropdown-item pad-left-10" onClick={() => setSport('cbb')}>
        <img
        src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cbb.png"
        width="auto"
        height="20"
        alt="Swish"
        className="mar-top-neg-3 pad-right-5"
      />
      CBB
      </DropdownItem>
        <DropdownItem className="swish-dropdown-item pad-left-10" onClick={() => setSport('cfb')}>
        <img
        src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cfb.png"
        width="auto"
        height="20"
        alt="Swish"
        className="mar-top-neg-3 pad-right-5"
      />
      CFB
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SportDropdown
