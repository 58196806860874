import React, { useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'

import { Column, Row } from 'simple-flexbox'

import {
  updateMemberRole
} from '../../../helpers/account'

const RoleButtons = ({ member, toggle }) => {
  const [ role, setRole ] = useState(member.role)
  const [ saveRequired, setSaveRequired ] = useState(false)
  const [ updateError, setUpdateError ] = useState(false)
  const [ updateSuccess, setUpdateSuccess ] = useState(false)

  const updateRole = r => {
    setSaveRequired(true)
    setRole(r)
  }

  const confirm = () => {
    updateMemberRole(member, role, err => {
      if (err) {
        setUpdateError(true)
        setSaveRequired(false)

        return
      }

      setSaveRequired(false)
      setUpdateSuccess(true)

      setTimeout(() => {
        setUpdateSuccess(false)
        toggle()
      }, 2000)
    })
  }


  return (
    <Row className="mar-top-0">
      <Column flexGrow={4}>
        <div className="text-left bg-170 klavika-regular pad-bottom-10">
          <b>Update Role</b>
        </div>
        <ButtonGroup>
          <Button
            active={role === 'read-only'}
            onClick={() => updateRole('read-only')}
            outline
            color="primary"
            className="rounded-left"
          >
            <b>Read-Only</b>
          </Button>
          <Button
            active={role === 'developer'}
            onClick={() => updateRole('developer')}
            outline
            color="primary"
            className="rounded-right"
          >
            <b>Developer</b>
          </Button>
        </ButtonGroup>
        {
          saveRequired
            ? (
              <div className="sm-90 yellow mar-top-10">
                <b>NOTE</b>
                <br />
                Changing a user&apos;s role from Developer
                to Read-Only will remove their API Key.
              </div>
            ) : null
        }

        {
          updateError
            ? (
              <div className="sm-90 yellow mar-top-5">
                There was an error saving your request, please try again later.
              </div>
            ) : null
        }
      </Column>

      <Column flexGrow={1}>
        <div className="mar-top-45">
          {
            saveRequired && !updateSuccess
              ? (
                <Button
                  className="rounded"
                  onClick={confirm}
                  color="danger"
                >
                  <b>CONFIRM</b>
                </Button>
              ) : null
          }

          {
            updateSuccess
              ? (
                <Button
                  className="rounded"
                  color="success"
                >
                  <b>SUCCESS!</b>
                </Button>
              ) : null
          }
        </div>
      </Column>
    </Row>
  )
}

export default RoleButtons
