import React from 'react'

import MLBGameState from './mlb'
import NFLGameState from './nfl'
import NBAGameState from './nba'

const GameStateContainer = props => {
  if (props.sport === 'mlb') return <MLBGameState {...props} />
  if (props.sport === 'nfl') return <NFLGameState {...props} />
  if (props.sport === 'nba') return <NBAGameState {...props} />

  return null
}

export default GameStateContainer
