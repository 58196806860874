import React from 'react'
import { Column, Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: '100vh',
    backgroundImage: "url('https://swish-assets.s3-us-west-2.amazonaws.com/imgs/spawn/api-bg-sunburst-nodes.jpg')",
    backgroundSize: 'cover'
  },
  content: {
    marginTop: 54
  },
  mainBlock: {
    padding: 30
  }
})

const Login = props => {
  const { children } = props

  return (
    <Row className={css(styles.container)}>
      <Column flexGrow={1} className={css(styles.mainBlock)}>
        <div className={css(styles.content)}>
          { children }
        </div>
      </Column>
    </Row>
  )
}

export default Login
