import React, { useState, useEffect } from 'react'
import Markdown from 'markdown-to-jsx'

import { isString } from 'lodash'

import { Column, Row } from 'simple-flexbox'

import { Redirect } from 'react-router-dom'

import { Element } from 'react-scroll'

import { useAccount } from '../context/account-context'

import Loading from './loading'


import {
  ResponseWindow,
  RequestWindow,
  ParametersTable,
  OpenExplorerButton,
  ResponseFieldsTable,
  FixedRightSideColumn,
  SimpleURLWindow,
  CustomTable,
  NoAccessNotifier,
  PostRequestWindow
} from '../components/documentation'

import {
  returnSpawnApiUrl
} from '../helpers/urls'

import {
  pathnameToEndpoint
} from '../helpers/general'

const loadDocContent = (endpoint, type, cb) => {
  const config = {
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  fetch(`${ returnSpawnApiUrl() }/docs/get?key=${ type }/${ endpoint }`,
    config)
    .then(res => res.json())
    .then(res => {
      console.log(res)
      if (res.status && !res.error.status) {
        cb(null, res.data.results)
      } else {
        cb(res.error)
      }
    })
    .catch(err => {
      debugger
      console.log(err, 'this is an error')
    })
}

const Documentation = props => {
  const { account, accountRoutes } = useAccount()
  const [ documentContent, setDocumentContent ] = useState(null)
  const [ hasAccess, setHasAccess ] = useState(null)
  const [ docNotFound, setDocNotFound ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const { type } = props


  const loadMarkdown = () => {
    setLoading(true)

    const { endpoint } = props.match.params
    const { type } = props

    loadDocContent(endpoint, type, (err, docRes) => {
      if (err) {
        if (err.message === 'Doc not found') {
          setDocNotFound(true)
          setLoading(false)
        }

        return
      }

      setDocumentContent(docRes)
      setLoading(false)
    })
  }

  const checkForUserAccess = () => {
    const { endpoint } = props.match.params
    const { type } = props

    if (accountRoutes) {
      const a = accountRoutes.find(b => (
        b.route === `/${ pathnameToEndpoint(endpoint) }`
      ))

      if ((a && a.hasAccess) || type === 'guides') {
        setHasAccess(true)

      } else {
        setHasAccess(false)
        setLoading(false)
      }
    }
  }

  // Init Load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    loadMarkdown()
  }, [ accountRoutes ])

  // load doc when user selects a new doc
  useEffect(() => {
    window.scrollTo(0, 0)
    setHasAccess(null)
    setLoading(true)
    loadMarkdown()
    // checkForUserAccess()
  }, [ props.match.params.endpoint ])


  if (loading) return <Loading />

  if (hasAccess === false) {
    const { endpoint } = props.match.params

    return (<NoAccessNotifier endpoint={endpoint} />)
  }

  if (docNotFound) {
    return <Redirect to="/" />
  }

  if (documentContent && !isString(documentContent)) {
    return <Redirect to="/" />
  }

  return (

    <Markdown
      options={{
        overrides: {
          Element,
          ResponseWindow,
          RequestWindow,
          ParametersTable,
          OpenExplorerButton,
          ResponseFieldsTable,
          FixedRightSideColumn,
          SimpleURLWindow,
          CustomTable,
          PostRequestWindow,
          Row: {
            component: Row,
            props: {
              wrap: true,
              horizontal: 'start'
            }
          },
          Column: {
            component: Column,
            props: {
              className: 'pad-15',
              flexGrow: 1
            }
          }
        }
      }}

      className="white"
      id={type}
    >
      { documentContent }
    </Markdown>
  )
}

export default Documentation
