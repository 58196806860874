import {
  returnSpawnApiUrl
} from './urls'


export const inviteMember = (data, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      email: data.email,
      org: data.org,
      name: data.name,
      oid: data.oid,
      role: data.role
    }),
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/account/invite`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const updateMemberRole = (user, role, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      did: user.did,
      role,
      apikey: user.apikey
    }),
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/user/role/update`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const createNewAPIKey = (user, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      did: user.did
    }),
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/apikeys/create`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const removeOrgMember = (did, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      did
    }),
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/account/deactivate`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const revokeAPIKey = (apikey, cb) => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/apikeys/deactivate/${ apikey }`,
    config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const loadOrgMembers = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/organizations/members`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const loadAccount = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/account`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        if (response.data.results.length === 0) {
          return cb(true)
        }

        return cb(null, response.data.results[ 0 ])
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const loadAccountRoutes = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/account/routes`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const loadPersonalAPIKey = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/apikeys`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const loadOrganizationAPIKey = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/apikeys?org=org`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}

export const requestRouteAccess = (route, cb) => {
  const config = {
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/route/access/${ route }`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {

        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}
