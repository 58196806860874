import React, { useState, useEffect } from 'react'
import { Row, Column } from 'simple-flexbox'
import {
  Button,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap'
import copy from 'copy-to-clipboard'
import Highlight from 'react-highlight'

import { useAccount } from '../../context/account-context'

import { replaceFakeAPIKeyText } from '../../helpers/general'

const PostRequestWindow = ({ url, children }) => {
  const [ copiedURL, setCopiedURL ] = useState(false)
  const [ copiedBody, setCopiedBody ] = useState(false)
  const { apikey } = useAccount()

  const copyURL = () => {
    const copyUrl = `https://${ url }`

    copy(replaceFakeAPIKeyText(copyUrl, apikey ? apikey.apikey : null))
    setCopiedURL(true)

    setTimeout(() => {
      setCopiedURL(false)
    }, 2000)
  }

  const copyBody = () => {
    copy(children)
    setCopiedBody(true)

    setTimeout(() => {
      setCopiedBody(false)
    }, 2000)
  }

  return (
    <Card
      className="card-request"
      style={{ display: 'block' }}

    >
      <CardTitle
        style={{ borderBottom: '1px solid #8894a8' }}
        className="pad-10"
      >
        <Row>
          <Column flexGrow={6} className="bg-120">
            Sample Request
          </Column>
        </Row>
      </CardTitle>
      <CardBody className="pad-top-0 bg-110 code-pink">
        <Row>
          <Column flexGrow={5} className="bg-120">
            URL
          </Column>
          <Column flexGrow={1}>
            <Button
              empty="true"
              onClick={copyURL}
              color={copiedURL ? 'dark' : 'dark'}
              size="sm"
              style={{ borderRadius: '15px' }}
            >
              {copiedURL ? 'Copied!' : 'Copy'}
            </Button>
          </Column>
        </Row>
        <code>
          { replaceFakeAPIKeyText(url, apikey ? apikey.apikey : null) }
        </code>

        <div
          className="mar-top-10 mar-bottom-10"
          style={{ borderBottom: '1px solid #8894a8' }}
        />

        <Row>
          <Column flexGrow={5} className="bg-120">
            Body
          </Column>
          <Column flexGrow={1}>
            <Button
              empty="true"
              onClick={copyBody}
              color={copiedBody ? 'dark' : 'dark'}
              size="sm"
              style={{ borderRadius: '15px' }}
            >
              {copiedBody ? 'Copied!' : 'Copy'}
            </Button>
          </Column>
        </Row>
        <Highlight
          className="json"
          style={{ maxHeight: '240px', background: '#1d1f21' }}
        >
          {children}
        </Highlight>
      </CardBody>
    </Card>
  )
}

export default PostRequestWindow
