import React from 'react'

import { useHistory } from 'react-router-dom'

import {
  Table
} from 'reactstrap'

import {
  toTitleCaseAndBold
} from '../../../helpers/general'

const MarketsContainer = ({ markets, onSelect, count, search }) => {
  const history = useHistory()

  return (
    <div>
      <div
        className="pad-10 bg-130 text-center klavika-regular">
        <b>Markets</b>
      </div>


      <Table size="sm" borderless className="">
        <tbody>
          <tr className="pad-10 search-section-header">
            <th className="width-2" />
            <th className="width-60 pad-10">Market</th>
            <th className="width-17 pad-10">ID</th>
            <th className="width-17 pad-10">Sport</th>
          </tr>
          {
            markets.slice(0, count).map(d => {
              return (
                <tr
                  key={d.statName + d.statTypeId}
                  className="pad-10 search-item"
                  onClick={() => {
                    history.push(`/markets/${ d.sport.toLowerCase() }`)
                    onSelect()
                  }}
                >
                  <th className="width-2" />
                  <td
                    className="width-60 pad-10"
                    dangerouslySetInnerHTML={
                      { __html: toTitleCaseAndBold(d.statName, search) }
                    }
                  />
                  <td className="width-17 pad-10">{d.statTypeId}</td>
                  <td className="width-17 pad-10">{d.sport}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export default MarketsContainer
