import React, { useState, useEffect } from 'react'

import {
  Button,
  Card,
  Table
} from 'reactstrap'

import {
  Row,
  Column
} from 'simple-flexbox'

import {
  returnSpawnApiUrl
} from '../../helpers/urls'

const TableHeader = ({ headers }) => {
  if (!headers || headers.length === 0) return null

  return (
    <thead>
      <tr>
        {
          headers.map(h => <th key={h} className="bord-none">{h}</th>)
        }
      </tr>
    </thead>
  )
}

const filterRefs = (search, ref) => {
  if (
    ref.referenceName.toLowerCase().indexOf(search.toLowerCase()) >= 0
    ||
    (ref.description &&
      ref.description.toLowerCase().indexOf(search.toLowerCase()) >= 0)
  ) return true

  return false
}

const collectEndpoints = references => {
  const endpoints = []

  references.forEach(ref => {
    const tempEndpointsArr = ref.endpoints.split(',')

    tempEndpointsArr.forEach(e => {
      if (endpoints.indexOf(e) === -1) {
        endpoints.push(e)
      }
    })
  })

  return endpoints
}

const filterBySport = (sport, ref) => {
  if (ref.sport.indexOf(sport) === -1) return false

  return true
}

const ReferenceTable = ({
  requestedType,
  type,
  sport,
  search,
  includeDescription,
  includeId=true,
  headers=''
}) => {
  const [ references, setReferences ] = useState([])
  const [ endpoints, setEndpoints ] = useState([])
  const [ showEndpoints, toggleShowEndpoints ] = useState(false)

  useEffect(() => {
    if (references.length === 0) {
      fetch(`${ returnSpawnApiUrl() }/documentation/reference/${ type }`)
        .then(res => res.json())
        .then(res => {
          if (res.data.status && res.data.results) {
            setReferences(res.data.results)
            setEndpoints(collectEndpoints(res.data.results))
          }
        })
    }
  }, [])

  if (requestedType && type !== requestedType) return null

  if (references.length === 0) return null

  const filteredReferences = references.filter(r => filterBySport(sport, r))

  if (filteredReferences.length === 0) return null

  return (
    <div>
      <Card
        className="pad-10 white mar-bottom-0"
        style={{ backgroundColor: '#374150' }}
      >
        <Row>
          <Column flex="2"><div className="bg-150"><b>{type}</b></div></Column>
          <Column flex="1">
            <Button
              className="sport-endpoint-button"
              onClick={() => toggleShowEndpoints(!showEndpoints)}
            >
              {showEndpoints ? 'HIDE' : 'SHOW'}
              {' '}
              ENDPOINTS
            </Button>

            <div className="mar-top-10">
            {
              showEndpoints ? endpoints.map(e => {
                if (e.indexOf(sport) === -1) return null

                return (<div key={e}><code>{e}</code></div>)
              })
                : null
            }
            </div>
          </Column>
        </Row>
      </Card>
      <Table size="md" className="white dark-back bg-110">
        <TableHeader headers={headers.split(',')} />
        <tbody>
          {
            filteredReferences.map(ref => {
              if (!filterRefs(search, ref)) return null

              return (
                <tr key={ref.referenceName}>
                  <td className="bord-top-grey-dark">{ref.referenceName}</td>
                  {
                    includeId ?
                      <td className="bord-top-grey-dark">{ref.referenceId}</td>
                      : null
                  }
                  {
                    includeDescription ?
                      <td className="bord-top-grey-dark">{ref.description}</td>
                      : null
                  }
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export default ReferenceTable


// <ReferenceTable type="marketOpenId" headers="marketOpenDescription, marketOpenId, Endpoints" />
