import React, { useState } from 'react'

import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'

import {
  returnSportLogo
} from '../../../../helpers/general'

const SportsDropdown = ({
  sport, updateSport
}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const sports = [ 'nba', 'nfl', 'mlb', 'nhl', 'cbb', 'cfb', 'atp' ]

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown
      className="mar-top-0 mar-left-20 mar-bottom-15"
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        className="sport-endpoint-dropdown text-left"
        style={{ padding: '10px', width: '90%' }}
      >

        <img
          src={returnSportLogo(sport)}
          width="auto"
          height="20"
          alt="Swish"
          className="mar-top-neg-3 pad-right-3"
        />

        <b>{' '}
          {sport.toUpperCase()}
        </b>

        <span
          className="enhanced-sports enhanced-sports-arrow-down2 float-right"
        />
      </DropdownToggle>

      <DropdownMenu
        className="rounded width-70"
        style={{ backgroundColor: 'rgb(41, 50, 63)', border: '0px' }}
      >
        {
          sports.map(sport => (
            <DropdownItem
              key={sport}
              className="swish-dropdown-item pad-left-10"
              onClick={() => updateSport(sport)}
            >
              <img
                src={returnSportLogo(sport)}
                width="auto"
                height="20"
                alt="Swish"
                className="mar-top-neg-3 pad-right-3"
              />
              <b>
                {' '}
                {sport.toUpperCase()}
              </b>
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  )
}

export default SportsDropdown
