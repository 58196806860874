import React, { useState } from 'react'
import moment from 'moment'

import SettingsIcon from '@material-ui/icons/Settings'
import PersonIcon from '@material-ui/icons/Person'
import CodeIcon from '@material-ui/icons/Code'
import BookIcon from '@material-ui/icons/MenuBook'

import {
  Button
} from 'reactstrap'

import {
  ThreeDots
} from 'react-loader-spinner'

import {
  toTitleCase
} from '../../../helpers/general'

import {
  createNewAPIKey
} from '../../../helpers/account'

const MemberItem = ({ member, loadAndSetOrgMembers, toggleSettingsModal, isCurrentUser }) => {
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)

  const createNewAPIKeyMiddleware = () => {
    setLoading(true)
    createNewAPIKey(member, err => {
      setLoading(false)
      if (err) {
        setError(true)

        setTimeout(() => {
          setError(false)
        }, 3000)

        return
      }

      loadAndSetOrgMembers(true)
    })
  }


  return (
    <tr>
      <td className="bord-top-grey-dark width-40">
        {member.email}
        {
          isCurrentUser
            ? (
              <span className="yellow sm-80">
                {' '}
                YOU
              </span>
            ) : null
        }
        {' '}
        {
          member.role === 'admin'
            ? <PersonIcon />
            : null
        }
        {
          member.role === 'developer'
            ? <CodeIcon />
            : null
        }
        {
          member.role === 'read-only'
            ? <BookIcon />
            : null
        }
      </td>
      <td className="bord-top-grey-dark width-20">
        {
          member.apikey && [ 'admin', 'developer' ].includes(member.role)
            ? `${ member.apikey.substr(0, 10) }***`
            : null
        }

        {
          !member.apikey && [ 'admin', 'developer' ].includes(member.role)
            ? (
              <Button
                className="grant-api-key-button sm-80 width-70"
                onClick={createNewAPIKeyMiddleware}
              >
                {
                  loading && !error
                    ? (
                      <ThreeDots
                        height="20"
                        width="20"
                        color="#ffffff"
                        visible
                        ariaLabel="three-dots-loading"
                      />
                    )
                    : null
                }

                {
                  !loading && !error
                    ? <>GRANT TEST KEY</>
                    : null
                }

                {
                  !loading && error
                    ? <>Error!</>
                    : null
                }
              </Button>
            )
            : null
        }

        {
          member.role === 'read-only'
            ? (
              <div className="light-blue">Read Only</div>
            )
            : null
        }
      </td>
      <td className={`bord-top-grey-dark width-15 ${ member.status === 'fledgling' ? 'orange' : ''}`}>
        {toTitleCase(member.status)}
      </td>
      <td className="bord-top-grey-dark width-15">
        { moment(member.enrolledAt).format('MM/DD/YYYY') }
      </td>
      <td className="bord-top-grey-dark width-10">
        <SettingsIcon
          className="pointer"
          onClick={() => toggleSettingsModal(member)}
        />
      </td>
    </tr>
  )
}

export default MemberItem
