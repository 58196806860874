import React from 'react'

import {
  Row,
  Column
} from 'simple-flexbox'


const TagItemNav = ({ filter, updateFilters }) => {
  return (
    <Row
      className="pad-5 pad-left-10 pad-right-10 rounded pointer mar-right-10 sm-90"
      style={{ border: '1px solid #CDDCEF', color: '#CDDCEF' }}
      onClick={() => updateFilters(filter)}
    >
      <Column flex={11}>
        <span>
          <span className="enhanced-sports enhanced-sports-tag4" />
          {' '}
          { filter.name }

        </span>
      </Column>

      <Column flex={1}>
        <span className="text-right enhanced-sports enhanced-sports-close3" />
      </Column>
    </Row>
  )
}

export default TagItemNav
