import React from 'react'

import {
  Input
} from 'reactstrap'

const NameInput = ({name, setName}) => {
  return (
    <div className="mar-top-0 width-80">
      <div className="text-left bg-170 klavika-regular pad-bottom-5">
        <b>NAME</b>
      </div>
      <Input
        type="text"
        value={name}
        className="rounded"
        onChange={e => setName(e.target.value)}
        placeholder="John Elway"
        style={{
          backgroundColor: 'rgb(41, 50, 63)', border: '0', color: '#ffffff'
        }}
      />
    </div>
  )
}

export default NameInput
