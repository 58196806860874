import React, { useState, useEffect } from 'react'
import { useAuth } from './auth-context'
import { loadPersonalAPIKey, loadAccountRoutes } from '../helpers/account'

const AccountContext = React.createContext()

const AccountProvider = props => {
  const {
    data: { account }
  } = useAuth()
  const [ apikey, setApiKey ] = useState(null)
  const [ accountRoutes, setAccountRoutes ] = useState(null)

  useEffect(() => {
    if (account && !apikey) {
      loadPersonalAPIKey((err, res) => {
        if (!err && res && res.length) {
          setApiKey(res[ 0 ])
        }
      })
    }

    if (account && !accountRoutes) {
      loadAccountRoutes((err, res) => {
        if (!err && res) setAccountRoutes(res)
      })
    }

    if (!account) {
      setApiKey(null)
      setAccountRoutes(null)
    }
  }, [ account ])

  const updateApiKey = tempApikey => {
    if (!apikey) return null

    setApiKey(tempApikey)
  }

  return (
    <AccountContext.Provider
      value={{
        account, apikey, accountRoutes, updateApiKey
      }}
      {...props}
    />
  )
}

const useAccount = () => {
  const context = React.useContext(AccountContext)

  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountProvider')
  }

  return context
}

export {
  AccountProvider,
  AccountContext,
  useAccount
}
