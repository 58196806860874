import React from 'react'
import {
  Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem
} from 'reactstrap'

import {
  Row,
  Column
} from 'simple-flexbox'

import { useAccount } from '../../context/account-context'
import RequestAccessButton from './request-access-button'

import {
  pathnameToEndpoint
} from '../../helpers/general'


const ProductSuiteModal = ({ response, isOpen, toggle, endpoint }) => {
  const { accountRoutes } = useAccount()

  const currentRoute = accountRoutes.find(a => a.route === `/${ endpoint }`)

  if (!currentRoute) return null

  const productSuiteRoutes = accountRoutes.filter(a => (
    a.productSuite === currentRoute.productSuite
    &&
    a.sport === currentRoute.sport
  ))

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      style={{
        maxWidth: '1000px',
        width: '70%',
        borderRadius: '10px',
        backgroundColor: '#374150',
        color: '#fff'
      }}
    >
      <ModalHeader toggle={toggle}>
        Thanks for your interest in this product, below are some related
        products you may be interested in as well.
      </ModalHeader>
      <ModalBody style={{
        overflowY: 'scroll',
        borderRadius: '8px',
        height: '80vh'
      }}
      >
        <ListGroup>
          {
            productSuiteRoutes.map(p => (
              <ListGroupItem
                key={p.route}
                style={{backgroundColor: '#374150'}}
              >
                <Row>
                  <Column flex="1">
                    {pathnameToEndpoint(p.route)}
                  </Column>
                  <Column flex="1">
                    <RequestAccessButton />
                  </Column>
                </Row>
              </ListGroupItem>
            ))
          }
        </ListGroup>
      </ModalBody>
    </Modal>
  )
}

export default ProductSuiteModal
