import React, { useState, useEffect } from 'react'

import { Column, Row } from 'simple-flexbox'

import {
  Button
} from 'reactstrap'

import EndpointDropdown from './endpoint-dropdown'
import SportDropdown from './sport-dropdown'

const findUrlParamEndpointSport = urlParamEndpoint => {
  if (!urlParamEndpoint) return null
  const sports = [ 'mlb', 'nfl', 'nba', 'nhl', 'cbb', 'cfb', 'atp' ]

  const sport = sports.find(s => urlParamEndpoint.toLowerCase().indexOf(s) >= 0)

  return sport || null
}

const ExplorerHeader = ({
  apikey,
  explorerEndpoint,
  setExplorerEndpoint,
  urlParamEndpoint,
  executeAPICall,
  loadingAPICall,
  history
}) => {
  const [ sport, setSport ] =
    useState(findUrlParamEndpointSport(urlParamEndpoint))

  useEffect(() => {

    if (urlParamEndpoint && !sport) {
      setSport(findUrlParamEndpointSport(urlParamEndpoint))
    }

  }, [ urlParamEndpoint ])

  const redirectUrl = urlParamEndpoint &&  urlParamEndpoint.includes('/')
    ? urlParamEndpoint.split('/').join('-').slice(1)
    : urlParamEndpoint

  return (
    <Row wrap horizontal="start" className="header-wrapper">
      <Column flex="2">

        <Row>
          <div className="mar-right-15">
            <SportDropdown sport={sport} setSport={setSport} />
          </div>
          <EndpointDropdown
            className="mar-left-10"
            sport={sport}
            urlParamEndpoint={urlParamEndpoint}
            explorerEndpoint={explorerEndpoint}
            setExplorerEndpoint={setExplorerEndpoint}
            apikey={apikey}
          />
        </Row>
      </Column>

      <Column className="pad-10" flex="3">
        <div>
          <b>API Key:</b>
          {' '}
          { apikey ? apikey.apikey : null }
        </div>
      </Column>

      <Column
        flexBasis="350px"
        className="execute-api-call-wrapper mar-left-10"
      >
        <Button className="execute-api-call" onClick={executeAPICall}>
          {
            loadingAPICall
              ? <span className="loading-spinner" />
              : <span><b>RUN API REQUEST</b></span>
          }
        </Button>
      </Column>

      <Column flexBasis="50px" className="mar-left-10">
        <Button
          className="exit-explorer-button"
          onClick={
            urlParamEndpoint
              ? () => history.push(`/docs/${ redirectUrl }`)
              : () => history.goBack()
          }
        >
          X
        </Button>
      </Column>

    </Row>
  )
}

export default ExplorerHeader
