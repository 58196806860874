import React , { useState } from 'react'

import PriorityHighIcon from '@material-ui/icons/PriorityHigh'

import { Tooltip } from 'reactstrap'

const SliderMark = ({ gameEvent }) => {
  const [ tooltipOpen, setTooltipOpen ] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <div
      onMouseEnter={() => setTooltipOpen(true)}
      className="sm-80"
      href="#"
      id={`TooltipExample${ gameEvent.index }`}
    >
      <PriorityHighIcon />
      <Tooltip
        placement="bottom"
        className="rounded"
        isOpen={tooltipOpen}
        target={`TooltipExample${ gameEvent.index }`}
        toggle={toggle}
        delay={{ show: 0, hide: 100 }}
      >
        <div className="text-center bg-170 mar-bottom-10 border-bottom">Notable Events</div>
        <ul>
          {
            gameEvent.customEvents.map(c => {
              return (<li key={c} className="mar-top-3 text-left"><div dangerouslySetInnerHTML={{__html: c}} /></li>)
            })
          }
        </ul>
      </Tooltip>
    </div>
  )
}

export default SliderMark
