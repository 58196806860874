import React, { useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'

import { Column, Row } from 'simple-flexbox'

import {
  removeOrgMember
} from '../../../helpers/account'

const RemoveMember = ({ member, toggle }) => {
  const [ saveRequired, setSaveRequired ] = useState(false)
  const [ updateError, setUpdateError ] = useState(false)
  const [ updateSuccess, setUpdateSuccess ] = useState(false)

  const confirm = () => {
    removeOrgMember(member.did, err => {
      if (err) {
        setUpdateError(true)
        setSaveRequired(false)

        return
      }

      setSaveRequired(false)
      setUpdateSuccess(true)

      setTimeout(() => {
        setUpdateSuccess(false)
        toggle()
      }, 2000)
    })
  }

  if (member.role === 'admin') return null

  return (
    <Row className="mar-top-50">
      <Column flexGrow={1}>
        <div className="text-left bg-170 klavika-regular pad-bottom-10">
          <b>Remove Member</b>
        </div>
        <Button
          color="danger"
          className="rounded"
          outline
          onClick={() => setSaveRequired(true)}
        >
          <b>REMOVE</b>
        </Button>

        {
          updateError
            ? (
              <div className="sm-90 yellow mar-top-5">
                There was an error saving your request, please try again later.
              </div>
            ) : null
        }
      </Column>

      <Column flexGrow={4} />

      <Column flexGrow={1}>
        <div className="mar-top-47">
          {
            saveRequired && !updateSuccess
              ? (
                <Button
                  className="rounded"
                  color="danger"
                  onClick={confirm}
                >
                  <b>CONFIRM</b>
                </Button>
              ) : null
          }

          {
            updateSuccess
              ? (
                <Button
                  className="rounded"
                  color="success"
                >
                  <b>SUCCESS!</b>
                </Button>
              ) : null
          }
        </div>
      </Column>
    </Row>
  )
}

export default RemoveMember
