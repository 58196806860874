import {
  returnSpawnApiUrl
} from './urls'

export const loadSearch = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  return fetch(`${ returnSpawnApiUrl() }/search`, config)
    .then(response => response.json())
    .then(response => {
      if (response.status && response.data.status) {
        return cb(null, response.data.results)
      }

      return cb(response.error.message)
    })
    .catch(err => cb(err))
}
