import React from 'react'
import { Column, Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: '100vh',
    color: '#fff'
  },
  content: {
    marginTop: 54
  },
  mainBlock: {
    backgroundColor: '#374150',
    paddingLeft: 270,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 15
  }
})

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
  }

  render () {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Row className={css(styles.container)}>
          <Column flexGrow={1} className={css(styles.mainBlock)}>
            Whoops! Something went wrong. Please try again later.
          </Column>
        </Row>
      )
    }

    return children
  }
}

export default ErrorBoundary
