import React, { useState, useEffect } from 'react'

import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import IconSearch from '../assets/icon-search'

import {
  Card,
  CardBody,
  Table
} from 'reactstrap'

import {
  Row,
  Column
} from 'simple-flexbox'

import {
  SportSelection,
  ReferenceSearchBar
} from '../components/reference'

import {
  returnSpawnApiUrl
} from '../helpers/urls'

const loadMarkets = cb => {
  fetch(`${ returnSpawnApiUrl() }/markets`)
    .then(res => res.json())
    .then(res => {
      if (res.data.results) return cb(res.data.results)
    })
    .catch(err => {
      console.log(err)
    })
}

const runSearch = (searchFrom, searchFor) => {
  if (!searchFor || !searchFrom) return true

  return searchFor.split(' ').every(v => {
    return searchFrom.toLowerCase().indexOf(v.toLowerCase()) >= 0
  })
}

const filterMarkets = (sport, search, market) => {
  if (sport !== market.sport.toLowerCase()) return false

  if (
    !runSearch(market.statName, search.toLowerCase())
    &&
    !runSearch(market.endpoints, search.toLowerCase())
  ) return false

  return true
}

const MarketCoverage = ({ match }) => {
  const [ sport, setSport ] = useState(match.params.sport || 'mlb')
  const [ search, setSearch ] = useState('')
  const [ markets, setMarkets ] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (markets.length === 0) {
      loadMarkets(res => {
        setMarkets(res)
      })
    }
  }, [])

  useEffect(() => {
    if (match.params.sport !== sport && match.params.sport) {
      setSport(match.params.sport)
    }
  }, [ match.params.sport ])

  useEffect(() => {
    setSearch('')
  }, [ sport ])

  return (
    <div>
      <h1 className="pt-3 klavika-regular white"><b>Market Coverage</b></h1>
      <dev className="mar-bottom-20 bg-120 light-blue klavika-regular">
        Below is a list outlining all available markets and associated
        endpoints, as well as an indicator for whether each is also available
        in our Bet Request or In Play endpoints.
      </dev>

      <Card className="mar-bottom-0" style={{ background: 'none' }}>
        <CardBody>
          <Row>
            <Column flex="4">
              <SportSelection sport={sport} setSport={setSport} />
            </Column>
            <Column className="pad-top-10">
              <IconSearch />
            </Column>
            <Column
              flex="4"
              className="bord-bottom-grey"
              style={{ height: '35px' }}
            >
              <ReferenceSearchBar
                search={search}
                setSearch={setSearch}
                placeholder={'Search for Markets'}
              />
            </Column>
          </Row>
        </CardBody>
      </Card>

      <Table size="md" className="white dark-back bg-110">
        <thead>
          <tr>
            <th className="bord-none">Market Name</th>
            <th className="bord-none">Stat Type ID</th>
            <th className="bord-none">Sport</th>
            <th className="bord-none">Endpoints</th>
            <th className="bord-none">Pre Game</th>
            <th className="bord-none">Bet Request</th>
            <th className="bord-none">In Play</th>
          </tr>
        </thead>
        <tbody>
          {
            markets.map(market => {
              if (!filterMarkets(sport, search, market)) return null

              return (
                <tr key={market.statTypeId}>
                  <td className="bord-top-grey-dark">{market.statName}</td>
                  <td className="bord-top-grey-dark">{market.statTypeId}</td>
                  <td className="bord-top-grey-dark">{market.sport}</td>
                  <td className="bord-top-grey-dark">
                    {
                      market.endpoints.split(',').map(e => {
                        return (
                          <>
                            <code key={e}>{e}</code>
                            <br />
                          </>
                        )
                      })
                    }
                  </td>
                  <td className="bord-top-grey-dark">
                    {
                      market.preGame ?
                        <Check className="green" />
                        :
                        <Clear className="red" />
                    }
                  </td>
                  <td className="bord-top-grey-dark">
                    {
                      market.betRequest ?
                        <Check className="green" />
                        :
                        <Clear className="red" />
                    }
                  </td>
                  <td className="bord-top-grey-dark">
                    {
                      market.inPlay ?
                        <Check className="green" />
                        :
                        <Clear className="red" />
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export default MarketCoverage
