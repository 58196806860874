import React, { useState } from 'react'
import moment from 'moment'

import {
  Table,
  Button,
  Card
} from 'reactstrap'

import { Link } from 'react-router-dom'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import {
  Column,
  Row
} from 'simple-flexbox'

import LogoutButton from '../logout-button'

import {
  toTitleCase
} from '../../../helpers/general'

import KeySection from './key-section'

const returnDeveloperStatus = status => {
//   0 = inactive
// 1 = Invited
// 2 = Active
// 3 = Pending
  switch (status) {
  case 0:
    return 'Inactive'
  case 1:
    return 'Invited'
  case 2:
    return 'Active'
  case 3:
    return 'Pending'
  default:
    return null
  }
}


const UserSection = ({ account, apikey, updateApiKey }) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)

  return (
    <Row>
      <Column
        className="pad-left-15 pad-right-15 white"
        justifyContent="center"
        flex="1"
      >
        <Row
          wrap
          flexGrow={1}
          horizontal="spaced"
          breakpoints={{ 900: 'column' }}
        >
          <Column flex="1" style={{ minWidth: 350 }}>

            <Card className="mar-10 dark-back bord-top-developer">

              <Table size="md" className="white dark-back bg-110">

                <thead>
                  <tr className="text-center">
                    <th colSpan="3">
                      <AccountCircleIcon
                        className="pad-right-5 mar-top-neg-5"
                      />
                      MY ACCOUNT
                    </th>
                  </tr>
                </thead>

                <tbody>

                  <tr>
                    <td className="bord-top-grey-dark"><b>Name</b></td>
                    <td className="bord-top-grey-dark">
                      {toTitleCase(account.name)}
                    </td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                  <tr>
                    <td className="bord-top-grey-dark"><b>Email</b></td>
                    <td className="bord-top-grey-dark">{ account.email }</td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                  <tr>
                    <td className="bord-top-grey-dark"><b>Organization</b></td>
                    <td className="bord-top-grey-dark">{account.orgName}</td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                  <tr>
                    <td className="bord-top-grey-dark"><b>Account Type</b></td>
                    <td className="bord-top-grey-dark">
                      {toTitleCase(account.role)}
                    </td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                  <tr>
                    <td className="bord-top-grey-dark"><b>Your Admin</b></td>
                    <td className="bord-top-grey-dark">
                      {toTitleCase(account.adminName)}
                    </td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                  <tr>
                    <td className="bord-top-grey-dark">
                      <b>Account Status</b>
                    </td>
                    <td className="bord-top-grey-dark">
                      { returnDeveloperStatus(account.status) }
                    </td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                  <tr>
                    <td className="bord-top-grey-dark">
                      <b>Member Since</b>
                    </td>
                    <td className="bord-top-grey-dark">
                      { moment(account.enrolledAt).format('MM/DD/YYYY') }
                    </td>
                    <td className="bord-top-grey-dark" />
                  </tr>

                </tbody>
              </Table>

            </Card>

            <Row className="pad-10">
              <Column>
                <Link
                  to="/change"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    className="sport-endpoint-button bg-120 klavika-regular"
                  >
                    <b>UPDATE PASSWORD</b>
                  </Button>
                </Link>
              </Column>
              <Column className="mar-left-15">
                <LogoutButton />
              </Column>
            </Row>
          </Column>

          <Column flex="1" className="mar-top-0" style={{ minWidth: 350 }}>
            {
              account.role === 'admin'
                ? (
                  <KeySection
                    type={'production'}
                    member={account}
                  />
                )
                : null
            }

            {
              [ 'admin', 'developer' ].includes(account.role)
                ? (
                  <KeySection
                    type={'test'}
                    member={account}
                  />
                )
                : null
            }
          </Column>
        </Row>
      </Column>
    </Row>
  )
}


export default UserSection
