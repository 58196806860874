import React, { useState, useEffect } from 'react'

import { scroller, Element } from 'react-scroll'

import {
  FAQItem,
  FAQNavBar
} from '../components/faq'

import {
  returnSpawnApiUrl
} from '../helpers/urls'

const loadMarkets = cb => {
  fetch(`${ returnSpawnApiUrl() }/faq`)
    .then(res => res.json())
    .then(res => {
      if (res.data.results) return cb(res.data.results)
    })
    .catch(err => {
      console.log(err)
    })
}

const runSearch = (searchFrom, searchFor, splitOn = ' ') => {
  if (!searchFor || !searchFrom) return true

  return searchFor.split(splitOn).every(v => (
    searchFrom.toLowerCase().indexOf(v.toLowerCase()) >= 0
  ))
}

const filterFAQsSearch = (search, faq) => {
  if (
    !runSearch(faq.question, search.toLowerCase())
    && !runSearch(faq.answer, search.toLowerCase())
    && !runSearch(faq.type, search.toLowerCase())
  ) return false

  return true
}

const filterFAQsFilters = (filters, faq) => {
  const tags = faq.type.split(',')
  const sports = faq.sports.split(',')

  return filters.length && !filters.some(f => (
    (f.type === 'tag' && tags.includes(f.name))
    || (f.type === 'sports' && (
      sports.includes(f.name)
    ))
  ))
}

const FAQ = ({ match }) => {
  const [ search, setSearch ] = useState('')
  const [ faqs, setFaqs ] = useState([])
  const [ filters, setFilters ] = useState([])

  useEffect(() => {
    if (faqs.length === 0) {
      loadMarkets(res => {
        setFaqs(res)
        if (match.params.id) {
          scroller.scrollTo(match.params.id, {
            duration: 750,
            delay: 25,
            smooth: true
          })
        } else {
          window.scrollTo(0, 0)
        }
      })
    }
  }, [])

  const updateFilters = filter => {
    let filterAlreadySelected = false

    const filtersCopy = filters.reduce((a, c) => {
      if (c.name !== filter.name && c.type !== filter.type) {
        a.push(c)
      } else {
        filterAlreadySelected = true
      }

      return a
    }, [])

    if (!filterAlreadySelected) filtersCopy.push(filter)

    setFilters(filtersCopy)
  }

  return (
    <div>
      <h1 className="pt-3 klavika-regular white"><b>FAQs</b></h1>
      <FAQNavBar
        search={search}
        setSearch={setSearch}
        filters={filters}
        updateFilters={updateFilters}
      />

      <div>
        {
          faqs.map(faq => {
            if (!filterFAQsSearch(search, faq)) return null

            if (filterFAQsFilters(filters, faq)) return null

            return (
              <>
                <Element name={faq.id} />
                <FAQItem
                  key={faq.id}
                  faq={faq}
                  filters={filters}
                  updateFilters={updateFilters}
                />
              </>
            )
          })
        }
      </div>
    </div>
  )
}

export default FAQ
