import React, { useState, useEffect } from 'react'

import {
  Table,
  Button,
  Card
} from 'reactstrap'

import copy from 'copy-to-clipboard'

import {
  ThreeDots
} from 'react-loader-spinner'

import {
  size
} from 'lodash'

import {
  loadPersonalAPIKey,
  loadOrganizationAPIKey,
  createNewAPIKey
} from '../../../helpers/account'


const KeySection = ({ type, member }) => {
  const [ apikey, setApikey ] = useState({})
  const [ reveal, setReveal ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ keyCreationLoading, setKeyCreationLoading ] = useState(false)
  const [ copiedKey, setCopiedKey ] = useState(false)
  const [ keyCreationError, setKeyCreationError ] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (type === 'production') {
      loadOrganizationAPIKey((err, res) => {
        setLoading(false)

        if (err) return

        setApikey(res)
      })
    } else {
      loadPersonalAPIKey((err, res) => {
        setLoading(false)

        if (err) return

        setApikey(res)
      })
    }
  }, [])

  const copyKey = () => {
    copy(apikey[ 0 ].apikey)
    setCopiedKey(true)

    setTimeout(() => {
      setCopiedKey(false)
    }, 2000)
  }

  const createNewAPIKeyMiddleware = () => {
    setKeyCreationLoading(true)
    createNewAPIKey(member, err => {
      setKeyCreationLoading(false)
      if (err) {
        return setKeyCreationError(true)
      }

      setKeyCreationError(false)

      loadPersonalAPIKey((loadErr, res) => {
        setLoading(false)

        if (loadErr) return

        setApikey(res)
      })
    })
  }

  const returnKey = apikeys => {
    if (loading) return '...'

    if ((!apikeys || !size(apikeys)) && type === 'production') {
      return <b>No API Key</b>
    }

    if ((!apikeys || !size(apikeys)) && type === 'test') {
      return (
        <Button
          className="admin-key-button sm-80 width-30"
          onClick={createNewAPIKeyMiddleware}
        >
          {
            keyCreationLoading
              ? (
                <ThreeDots
                  height="20"
                  width="20"
                  color="#ffffff"
                  visible
                  ariaLabel="three-dots-loading"
                />
              ) : (
                <>CREATE TEST-API KEY</>
              )
          }
        </Button>
      )
    }

    if (apikeys && !reveal) {
      return apikeys.map(apikey => {
        return (
          <div
            key={apikey.apikey}
          >
            <code>
              {apikey.apikey.slice(0, 10)}
              **********
            </code>
          </div>
        )
      })
    }

    return apikeys.map(apikey => {
      return (
        <div
          key={apikey.apikey}
        >
          <code>
            {apikey.apikey}
          </code>
        </div>
      )
    })
  }

  return (
    <Card className="mar-10 dark-back bord-top-yellow">

      <Table size="md" className="white dark-back bg-110 mar-bottom-0">
        <thead>
          <tr className="text-center">
            <th colSpan="3">
              {
                type === 'production'
                  ? 'ORGANIZATION API KEY'
                  : 'TEST API KEY'
              }
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2">
              {
                returnKey(apikey)
              }
            </td>
          </tr>
          {
            size(apikey)
              ? (
                <tr>
                  <td className="width-50 sm-90">
                    <Button
                      className={
                        `role-button rounded${ type === 'test' || size(apikey) === 1 ? '-left' : '' } sm-80`
                      }
                      onClick={apikey ? () => setReveal(!reveal) : null}
                    >
                      {
                        reveal
                          ? 'HIDE'
                          : 'REVEAL'
                      }
                    </Button>

                    {
                      type === 'test' || size(apikey) === 1
                        ? (
                          <Button
                            className="role-button rounded-right sm-80"
                            onClick={apikey ? () => copyKey() : null}
                          >
                            {
                              copiedKey
                                ? 'COPIED!'
                                : 'COPY'
                            }
                          </Button>
                        ) : null
                    }
                  </td>
                </tr>
              ) : null
          }
        </tbody>
      </Table>
    </Card>
  )
}

export default KeySection
