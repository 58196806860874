import React, { useState } from 'react'

import { Row } from 'simple-flexbox'

import { pathnameToEndpoint } from '../../helpers/general'

import ProductSuiteModal from './product-suite-modal'
import RequestAccessButton from './request-access-button'

const NoAccessNotifier = ({ endpoint }) => {
  const [ modalIsOpen, setModalIsOpen ] = useState(true)

  const toggle = () => setModalIsOpen(!modalIsOpen)

  return (
    <div className="product-suite-button white pad-20">
      <ProductSuiteModal
        endpoint={pathnameToEndpoint(endpoint)}
        isOpen={modalIsOpen}
        toggle={toggle}
      />
      <Row className="justify-content-center">
      Whoops! Looks like you don&#39;t have access to this endpoint,
         click the button below to request access.
      </Row>

      <Row className="justify-content-center pad-top-10">
        <RequestAccessButton
          endpoint={endpoint}
          clickNotifier={toggle}
        />
      </Row>
    </div>
  )
}

export default NoAccessNotifier
