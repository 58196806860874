import React from 'react'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'

import {
  Button
} from 'reactstrap'

import { useAccount } from '../../context/account-context'

import {
  pathnameToEndpoint
} from '../../helpers/general'


const OpenExplorerButton = ({ link }) => {
  const { accountRoutes } = useAccount()

  const eLink = pathnameToEndpoint(link).replace('/explorer', '')

  let hasAccess = false

  if (accountRoutes) {
    hasAccess = !!accountRoutes.find(a => a.route === eLink && a.hasAccess)
  }

  if (!hasAccess) return <div className="mar-15" />

  return (
    <div width="20%" className="mar-15" style={{ marginLeft: 0 }}>
      <Button className="rounded outline-btn" href={link}>
        Open in Explorer
        <OpenInBrowserIcon
          style={{ height: '18px', width: '18px', paddingLeft: '5px' }}
        />
      </Button>
    </div>
  )
}

export default OpenExplorerButton
