import React from 'react'
import {
  Route,
  Switch
} from 'react-router-dom'
import routes from './routes'
import {
  Breadcrumbs
} from './components/breadcrumbs'

import {
  useAuth
} from './context/auth-context'

import DashboardLayout from './components/layout/dashboard'
import LoginLayout from './components/layout/login'
import Login from './Views/auth/login'
import Register from './Views/auth/register'
import ForgotPW from './Views/auth/forgot-password'
import ResetPW from './Views/auth/reset-password'
import InviteUser from './Views/auth/invite'
import Welcome from './Views/welcome'
import Explorer from './Views/explorer'

const AppRouter = () => {
  const auth = useAuth()

  // Not logged in
  if (!auth.data.loggedIn) {
    return (
      <LoginLayout>
        <Switch>
          <Route
            path="/reset/:did/:secret"
            render={props => <ResetPW {...props} />}
          />
          <Route
            path="/invite/:did/:secret"
            render={props => <InviteUser {...props} />}
          />
          <Route path="/register" component={Register} />
          <Route path="/forgot" component={ForgotPW} />
          <Route path="/" component={Login} />
        </Switch>
      </LoginLayout>
    )
  }

  // User has access
  return (
    <Switch>
      <Route path="/explorer/:endpoint?" component={Explorer} />
      <DashboardLayout>
        {
          routes.map(({
            path, exact, type, Component
          }, key) => (
            <Route
              {...exact}
              path={path}
              key={key}
              render={props => {
                const crumbs = routes
                  .filter(({ path }) => props.match.path.includes(path))
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                        (path, param) => path.replace(
                          `:${ param }`, props.match.params[ param ]
                        ), path
                      )
                      : path,
                    ...rest
                  }))

                return (
                  <>
                    <Breadcrumbs crumbs={crumbs} />
                    <Component {...props} type={type} />
                  </>
                )
              }}
            />
          ))
        }

      </DashboardLayout>
    </Switch>

  // we can also do something similar if routes have different layouts
  // <Switch>
  //   <Route path="/docs/:endpoint" component={Documentation} />
  //   <Route path="/" component={() => <Dashboard content={<Landing />} />
  // </Switch>
  )
}

export default AppRouter
