import React, { useState, useEffect } from 'react'

import {
  returnSpawnApiUrl
} from '../../helpers/urls'

import {
  formatDescriptionsForBool
} from '../../helpers/general'

const ResponseItem = ({ item }) => {
  return (
    <div
      className="pad-10 pad-left-0 bg-110"
      style={{ borderBottom: '1px solid rgba(223, 224, 235, 0.2)' }}
    >
      <div>
        <span
          style={{ fontWeight: 'bold' }}
        >
          { item.responseName }
        </span>
        <span className="text-muted pad-left-10 text-right">
          {item.type}
        </span>
      </div>

      <div>
        <div
          className="mar-top-5 sm-95"
          dangerouslySetInnerHTML={{ __html: formatDescriptionsForBool(item.description) }}
        />
      </div>
      <div className="mar-top-5 sm-95">
        {
          item.allowNull
            ? <code>Allows Null</code>
            : null
        }
      </div>
    </div>
  )
}

const ResponseFieldsTable = ({ endpoint, minRequired }) => {
  const [ responses, setResponses ] = useState([])

  useEffect(() => {
    if (responses.length === 0) {
      fetch(
        `${ returnSpawnApiUrl() }/documentation/response-fields/${ endpoint }`)
        .then(res => res.json())
        .then(res => {
          if (res.data.status && res.data.results) {
            setResponses(res.data.results)
          }
        })
    }
  }, [])


  if (responses.length === 0) return null

  return (
    <div>
      {
        responses.map(response => (
          <ResponseItem
            key={response.responseName}
            item={response}
          />
        )
        )
      }
    </div>
  )
}

export default ResponseFieldsTable
