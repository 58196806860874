import React, { useEffect } from 'react'
import { Column, Row } from 'simple-flexbox'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useHistory } from 'react-router-dom'
import { useAccount } from '../context/account-context'


const GuidesLanding = () => {
  const history = useHistory()
  const { accountRoutes } = useAccount()

  // Init Load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="white">

      <Column flexGrow={1}>
        <Row wrap flex="1" horizontal="spaced" breakpoints={{ 900: 'column' }}>
          <Column
            className="p-5"
            flex="1"
            horizontal="start"
            breakpoints={{ 450: 'row' }}
          >
            <h1 className="pt-3"><b>Swish API Guides</b></h1>
            <h4 className="lh-30">
              Swish APIs enable you to offer the widest range of markets
              across the major U.S. sports.  Integrate all pre-match and in-play
              markets for NBA, NFL, MLB and NHL and access additional mapping
              endpoints for schedules, teams, players, and more.
            </h4>
            <h5 className="pt-2 light-blue lh-25">
              Our API Guides are organized around basic integration workflows:
               setting up new markets, ingesting and updating prices, mapping
               to existing games, teams and players, etc.
            </h5>
          </Column>

          <Column
            className="col_scroll p-5"
            flex="1"
            breakpoints={{ 450: 'row' }}
          >
            <br />
            <h2 className="pt-2"><b>Pre-Match Player Props</b></h2>
            <h4>The Most Expansive U.S. Player Props Offering</h4>
            <h5 className="pt-2 light-blue lh-25">
              Pre-match or In-Play?  What Sport?  Find yours below.
            </h5>
            {
              accountRoutes &&
              accountRoutes.findIndex(a => (
                a.route === '/nba/players/props/market/adjusted'
              )) >= 0
                ? (
                  <div
                    className="market-button-bg mt-4 relative"
                    onClick={() => {
                      history.push('/guides/nba-players-props')
                    }}
                    onKeyDown={() => {}}
                  >
                    <b className="bg-150">NBA Player Props</b>
                    <div className="light-blue">
                      Pre-match NBA Player Prop markets like Points, Rebounds,
                      Assists, etc.
                    </div>
                    <ArrowForwardIosIcon
                      className="choose-arrow"
                      fontSize="small"
                    />
                  </div>
                )
                : null
            }
          </Column>
        </Row>
      </Column>

    </div>
  )
}

export default GuidesLanding
