import { datadogRum } from '@datadog/browser-rum'

const dotenv = require('dotenv')
const envExpand = require('dotenv-expand')

envExpand(dotenv.config())

const env = process.env.REACT_APP_ENV
const applicationId = '42fe372a-f55b-4c51-8264-2106a013c09f'
const clientToken = 'puba9b4cc11f01f13b40fcd3de90db9434c'

const ddRum = () => datadogRum.init({
  applicationId,
  clientToken,
  site: 'datadoghq.com',
  service: 'developers',
  env,
  sampleRate: 100,
  trackInteractions: true
})


export default ddRum
