import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import EmailValidator from 'email-validator'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Row
} from 'reactstrap'

import { AuthContext } from '../../context/auth-context'

class Register extends Component {
  constructor (props) {

    super(props)

    this.submitRegistration = this.submitRegistration.bind(this)

    this.state = {
      registrationError: {},
      registrationFailedError: '',
      requesting: false,
      redirect: false,
      firstName: '',
      lastName: '',
      email: '',
      org: '',
      password: '',
      confirmPassword: ''
    }
  }

  submitRegistration () {
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      org
    } = this.state

    const {
      register
    } = this.context

    if (firstName === '') {

      return this.updateRegistrationError('firstName',
        'Please enter a first name.')
    }
    if (lastName === '') {

      return this.updateRegistrationError('lastName',
        'Please enter a last name.')
    }

    if (org === '') {

      return this.updateRegistrationError('org',
        'Please enter an Organization.')
    }
    if (email === '' || !EmailValidator.validate(email)) {

      return this.updateRegistrationError('email',
        'Please enter a valid email.')
    }
    if (password === '' || confirmPassword === '') {

      return this.updateRegistrationError('password',
        'Please fill out both password fields.')
    }
    if (password.length < 8 || confirmPassword.length < 8) {

      return this.updateRegistrationError('password',
        'Please ensure password is more than 8 characters long.')
    }
    if (password !== confirmPassword) {

      return this.updateRegistrationError('password',
        'Please ensure both password fields match.')
    }

    this.setState({
      registrationError: {},
      registrationFailedError: '',
      requesting: true
    })

    register({
      firstName, lastName, org, email, password
    }, registrationFailedError => {
      this.setState({
        registrationFailedError,
        requesting: false,
        redirect: !registrationFailedError
      })
    })
  }

  updateRegistrationError (field, error) {
    let { registrationError } = { ...this.state }

    registrationError = {}
    registrationError[ field ] = error

    return this.setState({
      registrationError,
      password: '',
      confirmPassword: ''
    })
  }

  render () {
    const {
      email,
      password,
      confirmPassword,
      registrationError,
      registrationFailedError,
      firstName,
      lastName,
      org,
      requesting,
      redirect
    } = this.state

    return (
      <div className="mt-4 flex-row align-items-center">
        <Row className="justify-content-center">
          {
            redirect
              ? <Redirect to="/" /> : null
          }
          <img
            // eslint-disable-next-line
            src="https://s3-us-west-2.amazonaws.com/swish-assets/imgs/business/sportsbook/api-logo-2019.png"
            className="img-api-logo"
            alt=""
          />
        </Row>

        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <form onSubmit={this.submitRegistration}>
                    <Form>
                      <h3>Create a Swish API account to get started.</h3>
                      <p className="text-muted">
                        Already have an account?
                        <Link
                          className="text-black"
                          to="/login"
                        >
                          {' '}
                          Login here.
                        </Link>
                      </p>
                      {
                        <span className="red">{registrationFailedError}</span>
                      }
                      <InputGroup className="mb-3">
                        <Input
                          invalid={!!registrationError.firstName}
                          type="text"
                          id="firstNameInput"
                          placeholder="First Name"
                          value={firstName}
                          onChange={e => this.setState({
                            firstName: e.target.value
                          })}
                        />
                        <FormFeedback>
                          {registrationError.firstName}
                        </FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Input
                          invalid={!!registrationError.lastName}
                          type="text"
                          id="lastNameInput"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={e => this.setState({
                            lastName: e.target.value
                          })}
                        />
                        <FormFeedback>
                          {registrationError.lastName}
                        </FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Input
                          invalid={!!registrationError.email}
                          type="text"
                          id="orgInput"
                          placeholder="Organization"
                          value={org}
                          onChange={e => (
                            this.setState({ org: e.target.value }))}
                        />
                        <FormFeedback>{registrationError.email}</FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Input
                          invalid={!!registrationError.email}
                          type="text"
                          id="emailInput"
                          placeholder="Email"
                          autoComplete="email"
                          value={email}
                          onChange={e => (
                            this.setState({ email: e.target.value }))}
                        />
                        <FormFeedback>{registrationError.email}</FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Input
                          invalid={!!registrationError.password}
                          type="password"
                          id="passwordInput"
                          placeholder="Password"
                          autoComplete="new-password"
                          value={password}
                          onChange={
                            e => this.setState({ password: e.target.value })
                          }
                        />
                        <FormFeedback>
                          {registrationError.password}
                        </FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <Input
                          invalid={!!registrationError.password}
                          type="password"
                          id="confirmPasswordInput"
                          placeholder="Repeat password"
                          autoComplete="new-password"
                          value={confirmPassword}
                          onChange={
                            e => this.setState({
                              confirmPassword: e.target.value
                            })
                          }
                        />
                      </InputGroup>
                      {
                        !requesting
                          ? (
                            <Button
                              id="submitCreateUser"
                              type="submit"
                              color="dark"
                              className="px-4"
                              onClick={this.submitRegistration}
                            >
                            CREATE ACCOUNT
                            </Button>
                          )
                          : (
                            <Button
                              color="dark"
                              className="px-4"
                              disabled
                            >
                              Loading...
                            </Button>
                          )
                      }
                    </Form>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

Register.contextType = AuthContext


export default Register
