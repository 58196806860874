import React from 'react'
import { bool, func, string } from 'prop-types'
import { Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'
import { useHistory } from 'react-router-dom'

import {
  toTitleCase
} from '../../helpers/general'


const styles = StyleSheet.create({
  activeBar: {
    height: 25,
    width: 3,
    backgroundColor: '#374150',
    position: 'absolute'
  },
  activeContainer: {
    borderLeft: '4px solid #FFFF00',
    paddingLeft: 6
  },
  activeTitle: {
    color: '#FFFF00'
  },
  container: {
    height: 25,
    cursor: 'pointer',
    ':hover': {
      color: '#1ED760',
      opactity: 1
    },
    paddingLeft: 10,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5
  },
  title: {
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#f4f4f4',
    marginLeft: 24
  },
  icons: {
    color: 'white'
  }
})

const MenuItem = props => {
  const history = useHistory()

  const {
    active, icon, title, link, route, bold, keepTitleAsIs, ...otherProps
  } = props

  return (
    <Row
      className={css(styles.container, active && styles.activeContainer)}
      vertical="center"
      onClick={() => {
        history.push(link)
      }}
      {...otherProps}
    >
      {active && <div className={css(styles.activeBar)} />}
      <span
        className={`
          ${ css(styles.title, active && styles.activeTitle) }
          ${ bold ? 'bold' : '' }
        `}
      >
        {
          title !== 'FAQ'
            ? keepTitleAsIs ? title : toTitleCase(title)
            : 'FAQ'}
      </span>
    </Row>
  )
}

MenuItem.propTypes = {
  active: bool,
  icon: func,
  title: string
}

export default MenuItem
