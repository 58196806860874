import React, { useState, useEffect } from 'react'

import {
  Table,
  Button,
  Card
} from 'reactstrap'

import {
  orderBy
} from 'lodash'

import {
  ThreeDots
} from 'react-loader-spinner'


import PublicIcon from '@material-ui/icons/Public'
import PersonIcon from '@material-ui/icons/Person'
import CodeIcon from '@material-ui/icons/Code'
import BookIcon from '@material-ui/icons/MenuBook'

import {
  Column,
  Row
} from 'simple-flexbox'

import MemberItem from './member-item'
import SettingsModal from '../update-member'
import InviteMemberModal from './invite-member-modal'

import {
  toAllCaps
} from '../../../helpers/general'

import {
  loadOrgMembers
} from '../../../helpers/account'

const OrgSection = ({ account }) => {
  const [ orgMembers, setOrgMembers ] = useState([])
  const [ settingsModalOpen, setSettingsModalOpen ] = useState(false)
  const [ updatingMember, setUpdatingMember ] = useState(null)
  const [ inviteMemberModalOpen, setInviteMemberModalOpen ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const loadAndSetOrgMembers = hideLoadingIcon => {
    if (!hideLoadingIcon) setLoading(true)
    loadOrgMembers((err, res) => {
      if (err) return setLoading(false)

      setOrgMembers(res)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadAndSetOrgMembers()
  }, [])

  const toggleSettingsModal = member => {
    setUpdatingMember(member)

    if (settingsModalOpen) loadAndSetOrgMembers(true)

    setSettingsModalOpen(!settingsModalOpen)
  }

  const toggleInviteModal = loadMembers => {
    if (loadMembers) loadAndSetOrgMembers()

    setInviteMemberModalOpen(!inviteMemberModalOpen)
  }

  return (
    <Row>
      <Column
        className="white"
        justifyContent="center"
        flex="1"
      >
        <SettingsModal
          member={updatingMember}
          isOpen={settingsModalOpen}
          toggle={toggleSettingsModal}
        />

        <InviteMemberModal
          isOpen={inviteMemberModalOpen}
          toggle={toggleInviteModal}
          admin={account}
        />

        <Card className="mar-10 dark-back bord-top-admin">

          <Table size="md" className="white dark-back bg-110 mar-bottom-0">

            <thead>
              <tr className="text-center">
                <th colSpan="4">
                  <PublicIcon className="pad-right-5 mar-top-neg-5" />
                  {
                    toAllCaps(account.orgName)
                  }
                </th>
              </tr>

              <tr>
                <th className="bord-top-grey-dark width-40">Email</th>
                <th className="bord-top-grey-dark width-20">API Key</th>
                <th className="bord-top-grey-dark width-15">Status</th>
                <th className="bord-top-grey-dark width-15">Added</th>
                <th className="bord-top-grey-dark width-10" />
              </tr>

            </thead>

            <tbody>

              {
                loading
                  ? (
                    <tr>
                      <td className="bord-top-grey-dark" />
                      <td className="bord-top-grey-dark" colSpan="3">
                        <ThreeDots
                          height="100"
                          width="100"
                          radius="9"
                          color="#ffffff"
                          visible
                          ariaLabel="three-dots-loading"
                        />
                      </td>
                    </tr>
                  ) : null
              }

              {
                !loading
                  ? (
                    orderBy(orgMembers, [ 'email', 'apikey' ]).map(member => {
                      return (
                        <MemberItem
                          key={member.did + member.apikey}
                          member={member}
                          loadAndSetOrgMembers={loadAndSetOrgMembers}
                          toggleSettingsModal={toggleSettingsModal}
                          isCurrentUser={member.did === account.did}
                        />
                      )
                    })
                  ) : null
              }

              <tr>
                <td className="bord-top-grey-dark width-40">
                  <Button
                    className="admin-key-button sm-80"
                    onClick={() => setInviteMemberModalOpen(true)}
                  >
                    + INVITE NEW DEVLOPER
                  </Button>
                </td>
                <td className="bord-top-grey-dark width-10" />
                <td
                  className="bord-top-grey-dark width-50 text-right"
                  colSpan="3"
                >
                  <span className="mar-right-15">
                    <PersonIcon height="10" />
                    {' '}
                    Admin
                  </span>
                  <span className="mar-right-15">
                    <CodeIcon height="10" />
                    {' '}
                    Developer
                  </span>
                  <span className="mar-right-15">
                    <BookIcon height="10" />
                    {' '}
                    Read Only
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Column>
    </Row>
  )
}



export default OrgSection
