import React, { Component } from 'react'

import { Column, Row } from 'simple-flexbox'

import {
  forOwn
} from 'lodash'

import {
  Breadcrumbs
} from '../components/breadcrumbs'

import {
  ExplorerHeader,
  EndpointDescription,
  ParamsContainer,
  JSONViewer
} from '../components/explorer'

import {
  RequestWindow
} from '../components/documentation'


import { AccountContext } from '../context/account-context'

class Explorer extends Component {
  constructor () {
    super()
    Explorer.contextType = AccountContext

    this.state = {
      urlParamEndpoint: null,
      explorerEndpoint: null,
      explorerParams: {},
      apiUrl: 'api.swishanalytics.com',
      apiResponse: null,
      loadingAPICall: false
    }

    this.setExplorerEndpoint = this.setExplorerEndpoint.bind(this)
    this.setExplorerParams = this.setExplorerParams.bind(this)
    this.executeAPICall = this.executeAPICall.bind(this)
  }

  componentDidMount () {
    const { match } = this.props

    window.scrollTo(0, 0)

    if (match.params.endpoint) {
      this.setState({
        urlParamEndpoint: `/${ match.params.endpoint.split('-').join('/') }`
      })
    }
  }

  setExplorerEndpoint (endpoint) {
    window.history.pushState(
      null, null, `/explorer/${ endpoint.route.split('/').join('-').substr(1) }`
    )

    this.setState({
      explorerEndpoint: endpoint,
      explorerParams: {},
      urlParamEndpoint: `${ endpoint.route.split('/').join('-').substr(1) }`
    }, () => {
      this.buildApiURL()
    })
  }

  setExplorerParams (params) {
    this.setState({
      explorerParams: params
    }, () => {
      this.buildApiURL()
    })
  }

  executeAPICall () {
    const { apiUrl, explorerEndpoint } = this.state

    if (!explorerEndpoint) return

    this.setState({
      loadingAPICall: true
    })

    fetch(`https://${ apiUrl }`)
      .then(res => res.json())
      .then(res => {
        this.setState({
          apiResponse: res,
          loadingAPICall: false
        })
      })
  }

  buildApiURL () {
    const { explorerEndpoint, explorerParams } = this.state
    const { apikey } = this.context

    let url = `api.swishanalytics.com${ explorerEndpoint.route }`

    url += `?apikey=${ apikey.apikey }`

    forOwn(explorerParams, (v, k) => {
      url += `&${ k }=${ v }`
    })

    this.setState({
      apiUrl: url
    })
  }

  render () {
    const { apikey } = this.context
    const {
      explorerEndpoint,
      explorerParams,
      apiUrl,
      apiResponse,
      urlParamEndpoint,
      loadingAPICall
    } = this.state

    const {
      history
    } = this.props

    return (
      <div className="light-dark-back white">
        <ExplorerHeader
          apikey={apikey}
          explorerEndpoint={explorerEndpoint}
          setExplorerEndpoint={this.setExplorerEndpoint}
          urlParamEndpoint={urlParamEndpoint}
          executeAPICall={this.executeAPICall}
          history={history}
          loadingAPICall={loadingAPICall}
        />
        <div className="pad-left-30 pad-right-30">
          <Breadcrumbs
            crumbs={[
              { path: '/', name: 'Home' },
              { path: '', name: 'Explorer' }
            ]}
          />
        </div>
        <Row wrap horizontal="start" className="explorer-wrapper">
          <Column flexGrow={1} className="explorer-item">
            <EndpointDescription explorerEndpoint={explorerEndpoint} />

            <ParamsContainer
              explorerEndpoint={explorerEndpoint}
              explorerParams={explorerParams}
              setExplorerParams={this.setExplorerParams}
            />
          </Column>
          <Column flexGrow={1} className="explorer-item">
            <RequestWindow>
              { [ apiUrl ] }
            </RequestWindow>
            <JSONViewer
              response={apiResponse}
            />
          </Column>
        </Row>
      </div>
    )
  }
}


export default Explorer
