import React, { Component } from 'react'
import EmailValidator from 'email-validator'
import PersonIcon from '@material-ui/icons/Person'
import LockIcon from '@material-ui/icons/Lock'

import { Link } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Container,
  Form,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'

import {
  Column,
  Row
} from 'simple-flexbox'

import { AuthContext } from '../../context/auth-context'

export class Login extends Component {
  constructor (props) {
    super(props)

    this.submitLogin = this.submitLogin.bind(this)

    this.state = {
      loginErr: '',
      email: '',
      password: '',
      requesting: false
    }
  }

  submitLogin (e) {
    e.preventDefault()
    const {
      email,
      password
    } = this.state

    const {
      login
    } = this.context

    if (email === '' || !EmailValidator.validate(email)) {

      return this.setState({
        loginErr: 'Please submit a valid email.',
        password: ''
      })
    }

    if (password === '' || password.length < 8) {
      return this.setState({
        loginErr: 'Please submit a valid password.',
        password: ''
      })
    }

    this.setState({
      loginErr: '',
      requesting: true
    })

    login({ email, password }, loginErr => {
      this.setState({
        requesting: false,
        loginErr
      })
    })
  }

  render () {
    const {
      email,
      password,
      loginErr,
      requesting
    } = this.state

    return (
      <div className="mt-7 flex-row align-items-center klavika-regular">
        <Container>
          <Row className="justify-content-center ">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">

                  <img
                    // eslint-disable-next-line
                    src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/spawn/api-logo-2023-full-black.png"
                    className="img-api-logo-sm"
                    alt=""
                  />

                  <CardBody>
                    <Form>
                      <div>
                        <h1><b>Login</b></h1>

                        <p className={loginErr ? 'red bg-130' : 'bg-130'}>
                          {loginErr || 'Sign In to your account'}
                        </p>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <PersonIcon />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Email"
                            autoComplete="email"
                            id="emailInput"
                            value={email}
                            onChange={
                              e => this.setState({ email: e.target.value })
                            }
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <LockIcon />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            id="passwordInput"
                            value={password}
                            onChange={
                              e => this.setState({ password: e.target.value })
                            }
                          />
                        </InputGroup>
                        <Row>
                          <Column flex="1">
                            {
                              !requesting
                                ? (
                                  <Button
                                    color="dark"
                                    id="submitLogin"
                                    className="px-4"
                                    onClick={e => this.submitLogin(e)}
                                    type="submit"
                                  >
                                    <b>LOGIN</b>
                                  </Button>
                                ) : (
                                  <Button
                                    color="dark"
                                    className="px-4"
                                    disabled
                                  >
                                    <b>Loading...</b>
                                  </Button>
                                )
                            }
                          </Column>
                          <Column flex="1" className="text-right">
                            <Link
                              to="/forgot"
                            >
                              <Button
                                color="link"
                                className="px-0 text-black"
                              >
                                Forgot password?
                              </Button>
                            </Link>
                          </Column>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>

              </CardGroup>
            </Col>

          </Row>
        </Container>
      </div>
    )
  }
}

Login.contextType = AuthContext

export default Login
