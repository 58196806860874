import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Row
} from 'reactstrap'

import {
  ThreeDots
} from 'react-loader-spinner'

import { AuthContext } from '../../context/auth-context'
import { authServerUrlBase } from '../../helpers/urls'

export class InviteUser extends Component {
  constructor (props) {
    super(props)

    this.submitPasswords = this.submitPasswords.bind(this)

    this.state = {
      password: '',
      confirmPassword: '',
      setPasswordError: {},
      invalidToken: false,
      loading: true,
      passwordSuccesfullySet: false,
      redirect: false,
      did: props.match.params.did,
      secret: props.match.params.secret
    }
  }

  componentDidMount () {
    const { did, secret } = this.state

    fetch(`${ authServerUrlBase() }/validate/invitation/${ did }/${ secret }`)
      .then(response => response.json())
      .then(res => {
        if (!res.status || res.error.status || !res.data.status) {
          this.setState({
            invalidToken: true,
            loading: false
          })

          return
        }

        this.setState({
          invalidToken: false,
          loading: false
        })
      })
      .catch(() => {
        this.setState({
          invalidToken: true,
          loading: false
        })
      })
  }

  updateSetPasswordError = (field, error) => {
    let { setPasswordError } = { ...this.state }

    setPasswordError = {}
    setPasswordError[ field ] = error

    return this.setState({
      setPasswordError,
      password: '',
      confirmPassword: ''
    })
  }

  returnCardBody = () => {
    const {
      invalidToken,
      loading,
      passwordSuccesfullySet,
      setPasswordError,
      submittingPasswordUpdate
    } = this.state

    if (invalidToken) {
      return (
        <div className="mar-top-10 bg-130">
          There appears to be an error, please notify your Swish Analytics
          contact.
        </div>
      )
    }

    if (loading) {
      return (
        <div className="mar-top-10 bg-130 text-center">
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <ThreeDots
              height="100"
              width="100"
              color="#000"
              visible
              ariaLabel="three-dots-loading"
            />
          </div>
        </div>
      )
    }

    if (passwordSuccesfullySet) {
      return (
        <div className="mar-top-10 bg-130">
            Password was successfully set, you will be redirected in a moment.
        </div>
      )
    }

    return (
      <>
        <div className="mar-top-10 bg-130">
          To complete your registration please create a
          password.
        </div>
        <Form className="mar-top-10">
          <FormGroup>
            <Input
              className="rounded bg-120 width-95"
              invalid={!!setPasswordError.password}
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              onChange={e => this.setState({ password: e.target.value })}
            />
            <FormFeedback>{setPasswordError.password}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Input
              className="rounded bg-120 width-95"
              invalid={!!setPasswordError.password}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              onChange={e => {
                this.setState({ confirmPassword: e.target.value })
              }}
            />
            <FormFeedback>{setPasswordError.password}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Button
              className="set-password-button bg-110"
              onClick={this.submitPasswords}
            >
              {
                submittingPasswordUpdate
                  ? (
                    <ThreeDots
                      height="30"
                      width="30"
                      color="#000"
                      visible
                      ariaLabel="three-dots-loading"
                    />
                  ) : (
                    <>SET PASSWORD</>
                  )
              }
            </Button>
          </FormGroup>
        </Form>
      </>
    )
  }

  submitPasswords () {
    const {
      password,
      confirmPassword,
      did,
      secret
    } = this.state

    const { setPW } = this.context

    if (password === '' || confirmPassword === '') {

      return this.updateSetPasswordError('password',
        'Please fill out both password fields.')
    }

    if (password.length < 8 || confirmPassword.length < 8) {

      return this.updateSetPasswordError('password',
        'Please ensure password is more than 8 characters long.')
    }

    if (password !== confirmPassword) {

      return this.updateSetPasswordError('password',
        'Please ensure both password fields match.')
    }

    this.setState({
      setPasswordError: {},
      submittingPasswordUpdate: true
    })

    setPW({
      did, secret, password
    }, setPasswordError => {
      if (setPasswordError) {
        this.setState({
          setPasswordError,
          submittingPasswordUpdate: false
        })

        return
      }

      this.setState({
        setPasswordError: false,
        passwordSuccesfullySet: true,
        submittingPasswordUpdate: false
      })

      setTimeout(() => {
        this.setState({
          redirect: true
        })
      }, 2000)
    })
  }


  render () {
    const {
      redirect
    } = this.state

    if (redirect) {
      return (<Redirect to="/" />)
    }


    return (
      <div className="flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4 bord-none klavika-regular">
                  <img
                    // eslint-disable-next-line
                    src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/spawn/api-logo-2023-full-black.png"
                    className="img-api-logo-sm"
                    alt=""
                  />

                  <CardBody>
                    {this.returnCardBody()}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

InviteUser.contextType = AuthContext



export default InviteUser
