import React, { useState, useEffect, useRef } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { debounce } from 'lodash'

const GuideContext = React.createContext()

const GuideProvider = props => {
  const [ guideIds, setGuideIds ] = useState([])
  const [ guideSections, setGuideSections ] = useState([])
  const [ guideSectionsPositions, setGuideSectionsPositions ] = useState([])
  const [ currentSection, setCurrentSection ] = useState(null)
  const stateRef = useRef()

  stateRef.current = currentSection

  const findCurrentSection = () => {
    let min = 100000
    let current = null

    guideIds.forEach(g => {
      const t = g.getBoundingClientRect().top

      if (Math.abs(t) < min && t < 40) {
        min = Math.abs(t)
        current = g
      }
    })

    setCurrentSection(current)
  }

  const getPosition = el => window.pageYOffset + el.getBoundingClientRect().top

  const getGuideSections = ids => {
    if (ids.length > 0) {
      const sections = []
      const positions = []

      const classes = Array.prototype.slice.call(ids)

      classes.forEach(i => {
        const name = i.getAttribute('name')

        positions.push({ name, position: getPosition(i) })
        sections.push(name)
      })

      setGuideIds(classes)
      setGuideSections(sections)
      setGuideSectionsPositions(positions)
    } else {
      setGuideIds([])
      setGuideSections([])
      setGuideSectionsPositions([])
    }
  }

  const updateCurrentSection = section => {
    const p = guideSectionsPositions.find(a => a.name === section)

    scroll.scrollTo(p.position)
  }


  useEffect(() => {
    if (guideIds.length > 0) {
      document.addEventListener('scroll',
        debounce(findCurrentSection, 150), false)
    } else {
      document.removeEventListener('scroll',
        debounce(findCurrentSection, 150), false)
    }
  }, [ guideIds ])

  return (
    <GuideContext.Provider
      value={{
        currentSection,
        updateCurrentSection,
        guideSections,
        getGuideSections
      }}
      {...props}
    />
  )
}

const useGuide = () => {
  const context = React.useContext(GuideContext)

  if (context === undefined) {
    throw new Error('useGuide must be used within a GuideProvider')
  }

  return context
}

export {
  GuideProvider,
  GuideContext,
  useGuide
}
