import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap'

import { AuthContext } from '../../context/auth-context'

export class ResetPW extends Component {
  constructor (props) {
    super(props)

    this.submitReset = this.submitReset.bind(this)
    this.state = {
      resetError: '',
      newPassword: '',
      newPassword2: '',
      redirect: false
    }
  }

  submitReset (e) {
    if (e) e.preventDefault()
    const {
      newPassword,
      newPassword2
    } = this.state

    const {
      match
    } = this.props

    const {
      resetPW
    } = this.context

    // eslint-disable-next-line react/prop-types
    const { did, secret } = match.params

    if (
      newPassword === ''
      || newPassword.length < 8
      || newPassword2 === ''
      || newPassword2.length < 8
    ) {

      return this.setState({
        resetError: 'Please submit a valid password.',
        newPassword: '',
        newPassword2: ''
      })
    }

    if (newPassword !== newPassword2) {

      return this.setState({
        resetError: 'New passwords do not match',
        newPassword: '',
        newPassword2: ''
      })
    }

    if (!did || !secret) {

      return this.setState({
        resetError: 'Please click on the link that was emailed to you',
        newPassword: '',
        newPassword2: ''
      })
    }


    resetPW({
      did, secret, newPassword, newPassword2
    }, resetError => {
      if (resetError) {
        this.setState({
          resetError,
          requesting: false
        })

        return
      }

      this.setState({
        resetError: false,
        passwordSuccessfullyChanged: true,
        requesting: false
      })

      setTimeout(() => {
        this.setState({
          redirect: true
        })
      }, 2000)
    })
  }

  render () {
    const {
      resetError,
      newPassword,
      newPassword2,
      passwordSuccessfullyChanged,
      redirect,
      requesting
    } = this.state

    return (
      <div className="app flex-row align-items-center">
        {
          redirect
            ? <Redirect to="/dashboard" /> : null
        }
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <div>
                        { !passwordSuccessfullyChanged
                          ? <h1>Reset Password</h1>
                          : <h1>Successfully updated</h1> }

                        {
                          !resetError
                            ? (
                              <p className="text-muted">
                              Please enter a new password
                              </p>
                            )
                            : (
                              <p className="red">
                              There was an error with resetting your password.
                              </p>
                            )
                        }
                        {
                          !passwordSuccessfullyChanged
                            ? (
                              <div>
                                <InputGroup className="mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    type="password"
                                    placeholder="New password"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={
                                      e => {
                                        this.setState({
                                          newPassword: e.target.value
                                        })
                                      }
                                    }
                                  />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                  <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                      <i className="icon-lock" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    type="password"
                                    placeholder="New password again"
                                    id="newPassword2"
                                    value={newPassword2}
                                    onChange={
                                      e => {
                                        this.setState({
                                          newPassword2: e.target.value
                                        })
                                      }
                                    }
                                  />
                                </InputGroup>
                                <Row>
                                  <Col>
                                    {
                                      !requesting
                                        ? (
                                          <Button
                                            id="sendEmail"
                                            className="px-4 sport-endpoint-button"
                                            onClick={this.submitReset}
                                          >
                                          Reset Password
                                          </Button>
                                        )
                                        : (
                                          <Button
                                            className="px-4 sport-endpoint-button"
                                            disabled
                                          >
                                          Loading...
                                          </Button>
                                        )
                                    }
                                  </Col>
                                </Row>
                              </div>
                            )
                            : (
                              <div>
                                <p>
                                Your password has been updated.
                                Redirecting...
                                </p>
                              </div>
                            )
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

ResetPW.contextType = AuthContext

export default ResetPW
