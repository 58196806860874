import React from 'react'

import {
  Input
} from 'reactstrap'

const EmailInput = ({ email, setEmail, admin }) => {
  const orgEmail = admin.email.includes('@') ?
    `mike_trout@${ admin.email.split('@')[1] }` : 'Email'

  return (
    <div className="mar-top-30 width-80">
      <div className="text-left bg-170 klavika-regular pad-bottom-5">
        <b>EMAIL</b>
      </div>
      <Input
        type="text"
        value={email}
        className="rounded"
        onChange={e => setEmail(e.target.value)}
        placeholder={orgEmail}
        style={{
          backgroundColor: 'rgb(41, 50, 63)', border: '0', color: '#ffffff'
        }}
      />
    </div>
  )
}

export default EmailInput
