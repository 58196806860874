import React, { useState } from 'react'
import { Row, Column } from 'simple-flexbox'
import {
  Button,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap'
import copy from 'copy-to-clipboard'

import { useAccount } from '../../context/account-context'

import { replaceFakeAPIKeyText } from '../../helpers/general'

const RequestWindow = ({ children }) => {
  const [ copied, setCopied ] = useState(false)
  const { apikey } = useAccount()

  const copyURL = () => {
    const copyUrl = `https://${ children[ 0 ] }`

    copy(replaceFakeAPIKeyText(copyUrl, apikey ? apikey.apikey : null))
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <Card
      className="card-request"
      style={{ display: 'block' }}

    >
      <CardTitle
        style={{ borderBottom: '1px solid #8894a8' }}
        className="pad-10"
      >
        <Row>
          <Column flexGrow={5} className="bg-130 pad-left-10 klavika-regular">
            <b>Sample Request</b>
          </Column>
          <Column flexGrow={1}>
            <Button
              empty="true"
              onClick={copyURL}
              color={copied ? 'dark' : 'dark'}
              size="sm"
              style={{ borderRadius: '15px', marginTop:`-2px` }}
            >
              <b>{copied ? 'COPIED!' : 'COPY'}</b>
            </Button>
          </Column>
        </Row>
      </CardTitle>
      <CardBody className="pad-top-0 bg-110 code-pink">
        <code>
          { replaceFakeAPIKeyText(children[ 0 ],
            apikey ? apikey.apikey : null) }
        </code>
        <div className="light-blue sm-80 pad-5 pad-left-0">
          Paste the above URL in your browser to see the full response!
        </div>
      </CardBody>
    </Card>
  )
}

export default RequestWindow
