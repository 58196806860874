import React, { useState } from 'react'

import {
  Row
} from 'simple-flexbox'

import {
  Collapse
} from 'reactstrap'

import {
  orderBy
} from 'lodash'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import MenuItem from '../../sidebar-menuitem'

import {
  toTitleCase,
  orderSideBarItemsMiddleware
} from '../../../../helpers/general'

const formatTitle = (sport, route) => (
  route.substr(1).replace(sport, '').split('/').join(' ')
)

const formatLink = route => {
  if (route.productSuite === 'bet builder') {
    if (route.sidebarTitle === 'Bet Request') {
      return `bet-request/${ route.sport }`
    }

    if (route.sidebarTitle === 'Results') {
      return `bet-request-results/${ route.sport }`
    }

    if (route.sidebarTitle === 'In-Play Bet Request') {
      return `bet-request-inplay/${ route.sport }`
    }

    return `${ route.route.substr(1).split('/').join('-') }`
  }

  return `${ route.route.substr(1).split('/').join('-') }`
}

const returnSectionHeader = (ps, isOpen, toggle) => (
  <Row
    style={{
      height: 35,
      cursor: 'pointer',
      paddingLeft: 25,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5
    }}
    vertical="center"
    onClick={() => toggle(!isOpen)}
  >
    <span className="bg-110">
      <b>{toTitleCase(ps)}</b>
      {
        isOpen
          ? <ExpandLessIcon />
          : <ExpandMoreIcon />
      }
    </span>
  </Row>
)

const DocsSection = ({ productSuite, routes, sport, currentRoute }) => {
  const [ isOpen, setIsOpen ] =
    useState(currentRoute ? productSuite === currentRoute.productSuite : false)

  if (!routes) return null

  const filteredRoutes =
    orderSideBarItemsMiddleware(routes.filter(r => r.sport === sport), productSuite)

  if (productSuite === 'null' || filteredRoutes.length === 0) return null

  return (
    <div>
      { returnSectionHeader(productSuite, isOpen, setIsOpen) }
      <Collapse isOpen={isOpen}>
        {
          orderBy(filteredRoutes, [ 'orderByAttribute' ]).map(route => (
            <MenuItem
              key={route.route}
              title={route.sidebarTitle || formatTitle(sport, route.route)}
              route={route.route}
              active={
                currentRoute &&
                route.productSuite === currentRoute.productSuite &&
                route.sidebarTitle === currentRoute.sidebarTitle
              }
              link={`/docs/${ formatLink(route) }`}
              keepTitleAsIs
            />
          ))
        }
      </Collapse>
    </div>
  )
}

export default DocsSection
