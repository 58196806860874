import React from 'react'
import { Column, Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import SidebarLogo from './sidebar-logo'
import MenuItem from './sidebar-menuitem'
import MainSideBar from './types/main'
import DocsSideBar from './types/docs/container'
import GuideSideBar from './types/guides'

import { useAuth } from '../../context/auth-context'


const styles = StyleSheet.create({
  burgerIcon: {
    cursor: 'pointer',
    position: 'absolute',
    left: 24,
    top: 38
  },
  container: {
    backgroundColor: '#374150',
    color: '#ffffff',
    width: 200,
    paddingTop: 22,
    paddingBottom: 22,
    position: 'fixed'
  },
  containerMobile: {
    transition: 'left 0.5s, right 0.5s',
    position: 'absolute',
    width: 200,
    height: '100vh',
    zIndex: 901
  },
  mainContainer: {
    height: '100%',
    minHeight: '90vh'
  },
  mainContainerMobile: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  mainContainerExpanded: {
    width: '100%',
    minWidth: '100vh'
  },
  menuItemList: {
    height: 'calc(100vh - 95px)',
    // Removing Overflow for now to remove ugly scrollbar
    overflowY: 'scroll',
    // This could be used maybe '-webkit-scrollbar': 'background: none'
  },
  outsideLayer: {
    position: 'absolute',
    width: '100vw',
    minWidth: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.50)',
    zIndex: 900
  },
  separator: {
    borderTop: '1px solid #DFE0EB',
    marginTop: 16,
    marginBottom: 16,
    opacity: 0.06
  },
  hide: {
    left: -255
  },
  show: {
    left: 0
  }
})


const returnDynamicSidebar = pathname => {
  if (pathname.indexOf('docs/') >= 0) return <DocsSideBar pathname={pathname} />

  if (pathname.indexOf('guides/') >= 0) {
    return <GuideSideBar pathname={pathname} />
  }

  return <MainSideBar pathname={pathname} />
}

const Sidebar = props => {
  const history = useHistory()
  const { expanded, isMobile, pathname } = props
  const { logout } = useAuth()

  const toggleMenu = () => {
    props.onToggle(!props.expanded)
  }


  const renderBurger = () => {
    return (
      <div onClick={toggleMenu} className={css(styles.burgerIcon)}>
        <MenuIcon />
      </div>
    )
  }

  return (
    <div>
      <Row
        className={css(styles.mainContainer)}
        breakpoints={{
          768: css(
            styles.mainContainerMobile,
            expanded && styles.mainContainerExpanded
          )
        }}
      >
        {isMobile && !expanded && renderBurger()}
        <Column
          className={css(styles.container)}
          breakpoints={{
            768: css(
              styles.containerMobile,
              expanded ? styles.show : styles.hide
            )
          }}
        >
          <SidebarLogo />
          <Column className={`${css(styles.menuItemList)} menuItemListScroll`}>
            { returnDynamicSidebar(pathname) }
            <div style={{
              borderTop: '1px solid #DFE0EB',
              marginTop: 16,
              marginBottom: 16,
              opacity: 0.06
            }}
            />
            <MenuItem
              title="Account"
              active={pathname.indexOf('/account') >= 0}
              onClick={() => history.push('/account')}
              bold
            />
            <MenuItem
              title="Logout"
              onClick={logout}
              bold
            />
          </Column>
        </Column>
        {isMobile && expanded && (
          <div className={css(styles.outsideLayer)} onClick={toggleMenu} />
        )}
      </Row>
    </div>
  )
}

export default Sidebar
