import React from 'react'
import { Column, Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'

import {
  ThreeDots
} from 'react-loader-spinner'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: '100vh',
    color: '#fff',
    zIndex: 99999

  },
  content: {
    marginTop: 54
  },
  mainBlock: {
    backgroundColor: '#374150',
    alignItems: 'center',
    justifyContent: 'center'
  },
  solarsystemContainer: {
    position: 'fixed',
    top: '40%',
    left: '40%',
    marginTop: '-25px',
    marginLeft: '-25px'
  }
})

const Loading = () => (
  <Row horizontal="start" className={css(styles.container)}>
    <Column flexGrow={1} className={css(styles.mainBlock)}>
      <ThreeDots
        height="100"
        width="100"
        radius="9"
        color="#ffffff"
        visible
        ariaLabel="three-dots-loading"
      />
    </Column>
  </Row>
)

export default React.memo(Loading)
