import ChangePW from './Views/auth/change-password'
import Landing from './Views/landing'
import DocsLanding from './Views/docs-landing'
import Documentation from './Views/documentation'
import GuidesLanding from './Views/guides-landing'
import Reference from './Views/reference'
import Markets from './Views/markets'
import Replay from './Views/replay'
import Loading from './Views/loading'
import Account from './Views/auth/account'
import FAQ from './Views/faq'

export default [
  {
    path: '/',
    name: 'Home',
    Component: Landing,
    exact: { exact: true }
  },
  {
    path: '/docs-landing/:sport?',
    name: 'Documents',
    Component: DocsLanding,
    type: 'docs',
    exact: { exact: true }
  },
  {
    path: '/guides',
    name: 'Guides',
    Component: GuidesLanding,
    exact: { exact: true }
  },
  {
    path: '/reference/:type?/:sport?',
    name: 'Reference',
    Component: Reference,
    exact: { exact: false }
  },
  {
    path: '/markets/:sport?',
    name: 'Markets',
    Component: Markets,
    exact: { exact: false }
  },
  {
    path: '/faq/:id?',
    name: 'FAQ',
    Component: FAQ,
    exact: { exact: false }
  },
  {
    path: '/replay',
    name: 'Replay',
    Component: Replay,
    exact: { exact: false }
  },
  {
    path: '/account',
    name: 'Account',
    Component: Account,
    exact: { exact: false }
  },
  {
    path: '/loading',
    name: 'Loading',
    Component: Loading,
    exact: { exact: false }
  },
  {
    path: '/change',
    name: 'Change',
    Component: ChangePW,
    exact: { exact: false }
  },
  {
    path: '/docs/:endpoint',
    name: 'Doc',
    Component: Documentation,
    type: 'docs',
    exact: { exact: false }
  },
  {
    path: '/guides/:endpoint',
    name: 'Guide',
    Component: Documentation,
    type: 'guides',
    exact: { exact: false }
  }
]
