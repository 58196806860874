import React from 'react'

const EndpointDescription = ({ explorerEndpoint }) => {
  return (
    <div>
      <h1 className="klavika-regular">
        <b>{explorerEndpoint ? explorerEndpoint.endpointTitle : 'Select an Endpoint'}</b>
      </h1>
    </div>
  )
}

export default EndpointDescription
