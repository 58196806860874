import React, { useEffect } from 'react'
import { Column, Row } from 'simple-flexbox'
import { Link } from 'react-router-dom'
import {
  Button
} from 'reactstrap'
import LandingAccas from '../components/landing/pre-builts'


const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Column flexGrow={2} className="white text-center klavika-regular">

      <h1 className="bg-300"><b>Swish Analytics API Portal</b></h1>
      <p className="bg-170 light-blue">
        Explore documentation to integrate with our odds APIs.
      </p>


      <Row horizontal="start" className="mar-top-0 mar-bottom-0">
        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-organge-border-top nba-orange-back mar-top-10 mar-bottom-10"
            href="/docs-landing/nba"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nba.png"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-11"
            />
            <br />
            <b>NBA</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-orange-border-top mar-top-10 mar-bottom-10"
            href="/docs/nba-players-props-market-adjusted"
          >
            PRE-MATCH<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-orange-border-top mar-top-10 mar-bottom-10"
            href="/docs/nba-players-props-inplay"
          >
            IN-PLAY<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-orange-border-top mar-top-10 mar-bottom-10"
            href="/docs/bet-request/nba"
          >
            BET REQUEST<br/> (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-orange-border-top mar-top-10 mar-bottom-10"
            href="/docs/nba-requests-accumulators"
          >
            PRE-BUILT<br/> PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-orange-border-top mar-top-10 mar-bottom-10"
            href="/docs/nba-players-props-results"
          >
            RESULTING<br/><br/>
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nba-orange-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/nba"
          >
            MORE<br/> NBA
          </Button>
        </Column>

      </Row>



      <Row horizontal="start" className="mar-top-0 mar-bottom-0">

        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top nfl-green-back mar-top-10 mar-bottom-10"
            href="/docs-landing/nfl"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nfl.png"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-11"
            />
            <br />
            <b>NFL</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/nfl-players-props-market-adjusted"
          >
            PRE-MATCH<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/nfl-players-props-inplay"
          >
            IN-PLAY<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/bet-request/nfl"
          >
            BET REQUEST<br/> (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/nfl-requests-accumulators"
          >
            PRE-BUILT
            <br />
            PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/nfl-players-props-results-offense"
          >
            RESULTING
            <br />
            <br />
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nfl-green-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/nfl"
          >
            MORE
            <br />
            NFL
          </Button>
        </Column>
      </Row>





      <Row horizontal="start" className="mar-top-0 mar-bottom-0">

        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mlb-red-back mar-top-10 mar-bottom-10"
            href="/docs-landing/mlb"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/mlb.png"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-11"
            />
            <br />
            <b>MLB</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mar-top-10 mar-bottom-10"
            href="/docs/mlb-players-props"
          >
            PRE-MATCH
            <br />
            PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mar-top-10 mar-bottom-10"
            href="/docs/mlb-players-props-inplay-cumulative"
          >
            IN-PLAY
            <br />
            PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mar-top-10 mar-bottom-10"
            href="/docs/bet-request/mlb"
          >
            BET REQUEST
            <br />
            (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mar-top-10 mar-bottom-10"
            href="/docs/mlb-requests-accumulators"
          >
            PRE-BUILT
            <br />
            PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mar-top-10 mar-bottom-10"
            href="/docs/mlb-players-props-results-batters"
          >
            RESULTING
            <br />
            <br />
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing mlb-red-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/mlb"
          >
            MORE
            <br />
            MLB
          </Button>
        </Column>
      </Row>


      <Row horizontal="start" className="mar-top-0 mar-bottom-0">

        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nhl-blue-border-top nhl-blue-back mar-top-10 mar-bottom-10"
            href="/docs-landing/nhl"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nhl.png"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-11"
            />
            <br />
            <b>NHL</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nhl-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/nhl-players-props-market-adjusted"
          >
            PRE-MATCH
            <br />
            PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing disabled nhl-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/nhl-players-props-market-adjusted"
          >
            IN-PLAY
            <br />
            PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nhl-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/bet-request/nhl"
          >
            BET REQUEST
            <br />
            (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nhl-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/nhl-requests-accumulators"
          >
            PRE-BUILT
            <br />
            PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nhl-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/nhl-players-props-results-skaters"
          >
            RESULTING
            <br />
            <br />
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing nhl-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/nhl"
          >
            MORE
            <br />
            NHL
          </Button>
        </Column>
      </Row>


      <Row horizontal="start" className="mar-top-0 mar-bottom-0">

        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cbb-blue-border-top cbb-blue-back mar-top-10 mar-bottom-10"
            href="/docs-landing/cbb"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cbb.png?vs=0.1"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-11"
            />
            <br />
            <b>CBB</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cbb-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/cbb-players-props"
          >
            PRE-MATCH<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing disabled cbb-blue-border-top mar-top-10 mar-bottom-10"
            href="#"
          >
            IN-PLAY<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cbb-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/bet-request/cbb"
          >
            BET REQUEST<br/> (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cbb-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/cbb-requests-accumulators"
          >
            PRE-BUILT<br/> PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cbb-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs/cbb-players-props-results"
          >
            RESULTING<br/><br/>
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cbb-blue-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/cbb"
          >
            MORE<br/>CBB
          </Button>
        </Column>
      </Row>






      <Row horizontal="start" className="mar-top-0 mar-bottom-0">

        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cfb-green-border-top cfb-green-back mar-top-10 mar-bottom-10"
            href="/docs-landing/cfb"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cfb.png"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-11"
            />
            <br />
            <b>CFB</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cfb-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/cfb-players-props"
          >
            PRE-MATCH<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing disabled cfb-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/cfb-players-props-market-adjusted"
          >
            IN-PLAY<br/> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cfb-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/bet-request/cfb"
          >
            BET REQUEST<br/> (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing disabled cfb-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/cfb-requests-accumulators"
          >
            PRE-BUILT<br/> PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cfb-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/cfb-players-props-results-offense"
          >
            RESULTING<br/><br/>
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing cfb-green-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/cfb"
          >
            MORE<br/>CFB
          </Button>
        </Column>
      </Row>


      <Row horizontal="start" className="mar-top-0 mar-bottom-0">

        <Column flex="0.5" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing atp-green-border-top atp-green-back mar-top-10 mar-bottom-10"
            href="/docs-landing/atp"
          >
            <img
              src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/atp-white.png"
              width="auto"
              height="35"
              alt="Swish"
              className="mar-top-neg-8"
            />
            <br />
            <b>ATP</b>

          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing atp-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/atp-players-props"
          >
            PRE-MATCH<br /> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing atp-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/atp-players-props-inplay"
          >
            IN-PLAY<br /> PROPS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing disabled atp-green-border-top mar-top-10 mar-bottom-10"
            href="#"
          >
            BET REQUEST<br /> (SGP)
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing disabled atp-green-border-top mar-top-10 mar-bottom-10"
            href="#"
          >
            PRE-BUILT<br /> PARLAYS
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing atp-green-border-top mar-top-10 mar-bottom-10"
            href="/docs/atp-players-props"
          >
            RESULTING<br /><br />
          </Button>
        </Column>

        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
          <Button
            className="sport-endpoint-button-bg-landing atp-green-border-top mar-top-10 mar-bottom-10"
            href="/docs-landing/atp"
          >
            MORE<br/>ATP
          </Button>
        </Column>
      </Row>


      {/*** NEW PRODUCT HIGHLIGHT ***/}
      <div className="mar-top-30 mar-bottom-30 pad-30">
        <LandingAccas />
      </div>
      {/*** NEW PRODUCT HIGHLIGHT ***/}
    </Column>
  )
}

export default Landing
