/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'react-router-dom'
import {
  capitalizeSportsFromURL
} from '../../helpers/general'


const getLabel = path => {
  const idx = path.lastIndexOf('/')
  const res = path.substring(idx + 1)

  return capitalizeSportsFromURL(res)
}

const Breadcrumbs = ({ crumbs }) => {
  // Hide breadcrumbs when on home
  if (crumbs.length <= 1) {
    return null
  }

  return (
    <div>

    </div>
  )
}

export default Breadcrumbs
