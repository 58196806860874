import React from 'react'

import {
  Button
} from 'reactstrap'

const RoleButton = ({ role, setRole }) => (
  <div className="mar-top-30">
    <div className="text-left bg-170 klavika-regular pad-bottom-5">
      <b>ROLE</b>
    </div>
    <Button
      className={
        `role-button
        ${ role === 'read-only' ? 'active' : '' }
        rounded-left sm-80`
      }
      onClick={() => setRole('read-only')}
    >
      READ-ONLY
    </Button>

    <Button
      className={
        `role-button
        ${ role === 'developer' ? 'active' : '' }
        rounded-right sm-80`
      }
      onClick={() => setRole('developer')}
    >
      DEVELOPER
    </Button>

    <div className="mar-top-10">
      {
        role === 'read-only'
          ? (
            <div className="yellow sm-90">
              Users with <u>Read-Only</u> access will only have access to
              documentation. They will not be given an API Key.
            </div>
          ) : null
      }

      {
        role === 'developer'
          ? (
            <div className="yellow sm-90">
              Users with <u>Developer</u> access will have access to the
              documentation and will be given a test API Key.
            </div>
          ) : null
      }
    </div>
  </div>
)

export default RoleButton
