import React, { useState } from 'react'
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap'

import {
  RoleButton,
  NameInput,
  EmailInput,
  SubmitButton
} from './inputs'

import {
  inviteMember
} from '../../../helpers/account'

const InviteMemberModal = ({ isOpen, toggle, admin }) => {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ role, setRole ] = useState('')

  const [ nameError, setNameError ] = useState(false)
  const [ emailError, setEmailError ] = useState(false)
  const [ roleError, setRoleError ] = useState(false)
  const [ inviteError, setInviteError ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const [ inviteSuccess, setInviteSuccess ] = useState(false)

  const inviteUser = () => {
    if (!name) return setNameError(true)

    if (!email) return setEmailError(true)

    if (!role) return setRoleError(true)

    const data = {
      email,
      org: admin.orgName,
      name,
      oid: admin.oid,
      role
    }

    setLoading(true)

    inviteMember(data, err => {
      if (err) return setInviteError(true)

      setInviteSuccess(true)

      setLoading(false)

      setInviteError(false)

      setTimeout(() => {
        toggle(true)
        setInviteSuccess(false)
        setName('')
        setEmail('')
        setRole('')
      }, 2000)
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      style={{
        maxWidth: '600px',
        width: '70%',
        borderRadius: '10px',
        backgroundColor: '#374150',
        color: '#fff'
      }}
    >
      <ModalHeader
        toggle={toggle}
        className="klavika-regular pad-30 pad-top-15 pad-bottom-15"
      >
        <b className="bg-140">
          Invite New Member
        </b>
      </ModalHeader>
      <ModalBody style={{
        borderRadius: '8px',
        padding: '30px'
      }}
      >
        <NameInput
          name={name}
          setName={setName}
        />
        <EmailInput
          email={email}
          setEmail={setEmail}
          admin={admin}
        />
        <RoleButton
          role={role}
          setRole={setRole}
        />

        <SubmitButton
          inviteUser={inviteUser}
          role={role}
          inviteSuccess={inviteSuccess}
          loading={loading}
        />

        {
          inviteError
            ? (
              <div className="red text-center bg-120 mar-top-10">
                There was an error completing the invite,
                please try again later.
              </div>
            ) : null
        }
      </ModalBody>
    </Modal>
  )
}

export default InviteMemberModal
