import React from 'react'

import {
  Button
} from 'reactstrap'

const SportSelection = ({ sport, setSport }) => {
  return (
    <div className="row">
      <Button
        color={sport === 'mlb' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('mlb')}
      >
        MLB
      </Button>
      <Button
        color={sport === 'nfl' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('nfl')}
      >
        NFL
      </Button>
      <Button
        color={sport === 'nba' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('nba')}
      >
        NBA
      </Button>
      <Button
        color={sport === 'nhl' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('nhl')}
      >
        NHL
      </Button>
      <Button
        color={sport === 'cbb' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('cbb')}
      >
        CBB
      </Button>
      <Button
        color={sport === 'cfb' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('cfb')}
      >
        CFB
      </Button>
      <Button
        color={sport === 'atp' ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport('atp')}
      >
        ATP
      </Button>
    </div>
  )
}

export default SportSelection
