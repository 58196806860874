import React from 'react'

import { useHistory } from 'react-router-dom'

import {
  Table
} from 'reactstrap'

import {
  toTitleCaseAndBold,
  boldString
} from '../../../helpers/general'

const getSportForLink = s => {
  if (!s) return ''

  return s.split(',')[ 0 ]
}

const ReferenceContainer = ({ references, onSelect, count, search }) => {
  const history = useHistory()

  return (
    <div>
      <div
        className="pad-10 bg-130 text-center klavika-regular">
        <b>Reference</b>
      </div>

      <Table size="sm" borderless>
        <tbody>
          <tr className="pad-10 search-section-header">
            <th className="width-2 pad-10"></th>
            <th className="width-35 pad-10">Name</th>
            <th className="width-10 pad-10">ID</th>
            <th className="width-25 pad-10">Type</th>
            <th className="width-28 pad-10">Sport</th>
          </tr>
          {
            references.slice(0, count).map(d => {
              return (
                <tr
                  key={d.referenceName + d.referenceId + d.sport}
                  className="pad-10 search-item rounded pointer"
                  onClick={() => {
                    history.push(
                      `/reference/${
                        d.referenceGroup.toLowerCase()
                      }/${ getSportForLink(d.sport) }`
                    )
                    onSelect()
                  }}
                  onKeyUp={() => {}}
                >
                  <td className="width-2" />
                  <td
                    className="width-35 pad-10"
                    dangerouslySetInnerHTML={
                      {
                        __html:
                          d.referenceName.length <= 26
                            ? d.referenceName
                            : `${ d.referenceName.slice(0, 26) }...`
                      }
                    }
                  />
                  <td className="width-10 pad-10">{d.referenceId || '-'}</td>
                  <td
                    className="width-25 pad-10"
                    dangerouslySetInnerHTML={
                      { __html: boldString(d.referenceGroup, search) }
                    }
                  />
                  <td
                    className="width-28"
                    dangerouslySetInnerHTML={
                      {
                        __html: boldString(d.sport || '', search).toUpperCase()
                      }
                    }
                  />
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export default ReferenceContainer
