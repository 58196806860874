import React, { useRef, useEffect } from 'react'

const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ ref ])
}

const OutsideAlerter = ({ children, callback }) => {
  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef, callback)

  return (
    <div ref={wrapperRef}>
      {children}
    </div>
  )
}

export default OutsideAlerter
