import React, { Component } from 'react'
import copy from 'copy-to-clipboard'
import {
  Button
} from 'reactstrap'


import {
  Column,
  Row
} from 'simple-flexbox'

import { AccountContext } from '../../context/account-context'

import {
  UserSection,
  OrgSection
} from '../../components/account'

import {
  toTitleCase
} from '../../helpers/general'

class Account extends Component {
  constructor (props) {
    super(props)

    this.state = {
      copyIconColor: 'info',
      panel: 'user'
    }
    this.toggleCopyButton = this.toggleCopyButton.bind(this)

    window.scrollTo(0, 0)
  }

  copyKey (k) {
    this.setState({
      copyIconColor: copy(k) ? 'green' : 'red'
    }, () => setTimeout(this.toggleCopyButton, 2500))
  }

  toggleCopyButton () {
    this.setState({
      copyIconColor: 'info'
    })
  }

  toggleDropdown () {
    const { dropdownOpen } = this.state

    this.setState({ dropdownOpen: !dropdownOpen })
  }

  render () {
    const { panel } = this.state
    const {
      account, apikey, updateApiKey
    } = this.context

    return (
      <Row>
        <Column
          className="pad-left-15 pad-right-15 white"
          justifyContent="center"
          flex="1"
        >

          <div className="text-left bg-220 pad-15 klavika-regular">

            <b>{ toTitleCase(account.name) }</b> / {account.orgName}
            <div className="mar-left-20 badge-developer">
              {account.role ? account.role.toUpperCase() : ''}
            </div>

            <span className="text-muted font-italic">{ account.title }</span>

            {
              account.role === 'admin'
                ? (
                  <Button
                    className="sport-endpoint-button pull-right mar-10 sm-65"
                    onClick={() => {
                      this.setState({
                        panel: panel === 'user' ? 'org' : 'user'
                      })
                    }}
                  >
                    SWITCH TO
                    {panel === 'user' ? ' ORGANIZATION' : ' USER PROFILE'}
                  </Button>
                )
                : null
            }

          </div>

          {
            panel === 'user'
              ? (
                <UserSection
                  apikey={apikey}
                  account={account}
                  updateApiKey={updateApiKey}
                />
              )
              : null
          }

          {
            panel === 'org'
              ? (
                <OrgSection
                  account={account}
                  updateApiKey={updateApiKey}
                />
              )
              : null
          }
        </Column>
      </Row>
    )
  }
}

Account.contextType = AccountContext


export default Account
