import React, { useState, useEffect } from 'react'
import { Row, Column } from 'simple-flexbox'
import {
  Button,
  Card,
  CardBody
} from 'reactstrap'
import copy from 'copy-to-clipboard'

import { useAccount } from '../../context/account-context'

import { loadPersonalAPIKey } from '../../helpers/account'

import { replaceFakeAPIKeyText } from '../../helpers/general'

const SimpleURLWindow = ({ url }) => {
  const [ copied, setCopied ] = useState(false)
  const [ apikey, setApiKey ] = useState(null)
  const { account } = useAccount()

  useEffect(() => {
    if (account && !apikey) {
      loadPersonalAPIKey((err, res) => {
        if (res) setApiKey(res.apikey)
      })
    }
  }, [ account ])

  const copyURL = () => {
    const copyUrl = `https://${ url }`

    copy(replaceFakeAPIKeyText(copyUrl, apikey))
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <Card
      className="card-request"
      style={{ display: 'block' }}
    >
      <CardBody className="bg-110 code-pink">
        <Row>
          <Column flex="9">
            <code>
              { replaceFakeAPIKeyText(url, apikey) }
            </code>
          </Column>
          <Column flex="1">
            <Button
              empty="true"
              onClick={copyURL}
              color={copied ? 'dark' : 'dark'}
              size="sm"
              style={{ borderRadius: '15px' }}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </Column>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SimpleURLWindow
