import React, { Component } from 'react'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'

import {
  Router
} from 'react-router-dom'

import AppRouter from './router'

import ddRum from './helpers/rum'

const createHistory = require('history').createBrowserHistory

const history = createHistory()

ddRum()

class App extends Component {
  render () {
    return (
      <Router history={history}>
        <AppRouter />
      </Router>
    )
  }
}

export default App
