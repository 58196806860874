export const authServerUrlBase = () => {
  const env = process.env.REACT_APP_ENV

  // switch (env) {
  // case 'local':
  //   return 'http://localhost:8080'
  // case 'development':
  // case 'stage':
  //   return 'https://auth-stage.swishanalytics.com'
  // default:
  //   return 'https://auth.swishanalytics.com'
  // }


  switch (env) {
  case 'local':
    return 'http://localhost:8080'
  case 'development':
    return 'https://auth.dev.swishanalytics.com'
  case 'stage':
    return 'https://auth-stage.swishanalytics.com'
  default:
    return 'https://auth.api.swishanalytics.com'
  }
}

export const returnSpawnApiUrl = () => {
  const env = process.env.REACT_APP_ENV
  const version = process.env.REACT_APP_VERSION

  switch (env) {
  case 'local':
    return `http://localhost:8081/${ version || 'v1' }`
  case 'development':
    return `https://docs-api.dev.swishanalytics.com/${ version || 'v1' }`
  case 'ci':
  case 'staging':
    return `https://stage-docs-api.swishaws.engineering/${ version || 'v1' }`
  default:
    return `https://docs-api.swishanalytics.com/${ version || 'v1' }`
  }
}
