import React from 'react'
import { Column, Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'

import {
  Card,
  CardBody,
  Col,
  Container
} from 'reactstrap'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: '100vh',
    backgroundImage: "url('https://swish-assets.s3-us-west-2.amazonaws.com/imgs/spawn/landing-back-1.jpg')",
    backgroundSize: 'cover'
  },
  content: {
    marginTop: 54
  },
  mainBlock: {
    padding: 30
  }
})

const Welcome = () => {
  return (
    <Row className={css(styles.container)}>
      <Column flexGrow={1} className={css(styles.mainBlock)}>
        <div className={css(styles.content)}>
          <div className="mt-4 flex-row align-items-center">
            <Row className="justify-content-center">
              <img
                // eslint-disable-next-line
                src="https://s3-us-west-2.amazonaws.com/swish-assets/imgs/business/sportsbook/api-logo-2019.png"
                className="img-api-logo"
                alt=""
              />
            </Row>

            <Container>
              <Row className="justify-content-center">
                <Col md="6">
                  <Card className="mx-4">
                    <CardBody className="p-20">
                      Thanks for signing up for access to our APIs; you're just about ready to plug into the world's most advanced sports prediction system! To get you started & on your way, a member of our team will reach out to you within the next 24 hours to learn more about your development needs & how we can best help you to utilize our APIs before providing your key.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Column>
    </Row>
  )
}

export default Welcome
