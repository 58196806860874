import React from 'react'

import {
  formatTeamNickname
} from '../../../helpers/general'

const MLBGameState = ({ currentGamestate }) => {

  if(!currentGamestate) return null

  return (
    <table className="width-100 helvetica">
      <tbody>

        <tr>
          <td className="text-left pad-5 width-10">
            <img
              alt="img"
              height="23px"
              className="pad-left-5"
              src={`https://swish-assets.s3-us-west-2.amazonaws.com/imgs/mlb/teams/${formatTeamNickname(currentGamestate.general.awayTeamNickname)}.png`}
            />
            {' '}
            {currentGamestate.general.awayTeamNickname}
          </td>
          <td className="text-center pad-5 width-5 bord-right-grey">
            <b className="">{currentGamestate.gamestate.awayTeamRuns}</b>
          </td>
          <td className="text-center pad-5 width-15 bord-right-grey">
            <b className="">{currentGamestate.gamestate.top === '1' ? 'TOP' : 'BOTTOM'} {currentGamestate.gamestate.inning}</b>
          </td>
          <td className="text-center pad-5 width-15" rowSpan="2">
            <img alt="img" height="40px" className="img-bases" src={`/dist/img/replay/bases-${currentGamestate.gamestate.bases}.png`} />
          </td>
          <td className="text-left pad-5 width-15 bord-right-grey">
            {
              currentGamestate.gamestate.pitchingTeam === currentGamestate.general.awayTeamId ?
              <span><b>Pitcher:</b> {currentGamestate.gamestate.pitcherName}</span>
              :
              <span><b>Batter:</b> {currentGamestate.gamestate.batterName}</span>
            }
          </td>
          <td className="text-left pad-5 width-25 bord-right-grey">
            <b>Last at Bat:</b> { currentGamestate.gamestate.lastAtBatDesc }
          </td>
        </tr>

        <tr className="bord-top-grey">
          <td className="text-left pad-5 width-10">
            <img
              alt="img"
              height="23px"
              className="pad-left-5"
              src={`https://swish-assets.s3-us-west-2.amazonaws.com/imgs/mlb/teams/${formatTeamNickname(currentGamestate.general.homeTeamNickname)}.png`}
            />
            {' '}
            {currentGamestate.general.homeTeamNickname}
          </td>
          <td className="text-center pad-5 width-5 bord-right-grey">
            <b className="">{currentGamestate.gamestate.homeTeamRuns}</b>
          </td>
          <td className="text-center pad-5 width-15 bord-right-grey">
            <b className="">{currentGamestate.gamestate.prePitchBalls}-{currentGamestate.gamestate.prePitchStrikes}, {currentGamestate.gamestate.prePitchOuts} OUT{currentGamestate.gamestate.prePitchOuts !== 1 ? 'S': ''}</b>
          </td>
          <td className="text-left pad-5 width-15 bord-right-grey">
            {
              currentGamestate.gamestate.pitchingTeam === currentGamestate.general.homeTeamId ?
              <span><b>Pitcher:</b> {currentGamestate.gamestate.pitcherName}</span>
              :
              <span><b>Batter:</b> {currentGamestate.gamestate.batterName}</span>
            }
          </td>
          <td className="text-left pad-5 width-25 bord-right-grey">
            <b>Last Pitch:</b> { currentGamestate.gamestate.lastPitchDesc }
          </td>
        </tr>

      </tbody>
      </table>
  )
}

export default MLBGameState
