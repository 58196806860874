import React from 'react'


const EndpointsList = ({ endpoints }) => {
  return (
    <div className="mar-top-10">
      {
        endpoints.map(endpoint => {
          return (
            <div key={endpoint}>
              <code className="">
                {endpoint === '*' ? 'All Endpoints' : `• ${endpoint}`}
              </code>
            </div>
          )
        })
      }
    </div>
  )
}

export default EndpointsList
