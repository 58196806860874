import React from 'react'

import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'

const style = { width: '100%', marginTop: 10, marginBottom: 10 };

const GamestateSlider = ({
  updateReplayCurrentIndex,
  replayCurrentIndex,
  gamestateData,
  marks,
  updateSlideCurrentIndex
}) => {
  return (
    <div style={style}>
      <Slider
        min={0}
        max={gamestateData.length - 1}
        included={false}
        marks={marks}
        value={replayCurrentIndex}
        onChange={updateSlideCurrentIndex}
        onAfterChange={updateReplayCurrentIndex}
      />
    </div>
  )
}

export default GamestateSlider
