import React, { useState } from 'react'

import Done from '@material-ui/icons/Done'

import {
  Button
} from 'reactstrap'

import {
  requestRouteAccess
} from '../../helpers/account'

const RequestAccessButton = ({ endpoint, clickNotifier }) => {
  const [ requestedAccess, setRequestedAccess ] = useState(false)

  const requestAccess = () => {
    requestRouteAccess(endpoint, err => {
      if (!err) setRequestedAccess(true)
      if (clickNotifier) clickNotifier()
    })
  }

  return (
    <Button
      className="sport-endpoint-button mar-top-5"
      onClick={requestAccess}
    >
      {
        requestedAccess
          ? (
            <div>
              <Done className="replay-green" />
              {' '}
              Access Requested
            </div>
          )
          : (
            <div>Request Access</div>
          )
      }
    </Button>
  )
}

export default RequestAccessButton
