import React, { useState, useEffect } from 'react'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap'

import {
  loadEndpoints
} from '../../../helpers/explorer'

const runSearch = (searchFrom, searchFor) => {
  if (!searchFor || !searchFrom) return true

  return searchFor.split(' ').every(v => {
    return searchFrom.toLowerCase().indexOf(v.toLowerCase()) >= 0
  })
}

const EndpointDropdown = ({
  apikey,
  sport,
  setSport,
  explorerEndpoint,
  setExplorerEndpoint,
  urlParamEndpoint
}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const [ endpointsLu, setEndpointsLU ] = useState([])
  const [ search, setSearch ] = useState('')

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const filterEndpoints = endpoint => {
    if (!sport && !search) return true

    if (sport && search) {
      return (
        endpoint.route.toLowerCase().indexOf(sport) >= 0
        &&
        runSearch(endpoint.route, search)
      )
    }

    if (sport) {
      return (
        endpoint.route.toLowerCase().indexOf(sport) >= 0
      )
    }

    if (search) {
      return (
        runSearch(endpoint.route, search)
      )
    }
  }

  useEffect(() => {
    if (endpointsLu && urlParamEndpoint && !explorerEndpoint) {
      const f = endpointsLu.find(e => e.route === urlParamEndpoint)

      if (f) setExplorerEndpoint(f)
    }
  }, [ endpointsLu ])


  // Wait for API Key to be loaded before loading endpoint lookup
  useEffect(() => {
    if (apikey && endpointsLu.length === 0) {
      loadEndpoints(apikey.apikey, (err, res) => {
        setEndpointsLU(res)

      })
    }
  }, [ apikey ])

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="sport-endpoint-dropdown" caret>
        { explorerEndpoint ?
          explorerEndpoint.route : 'Endpoint'}
      </DropdownToggle>
      <DropdownMenu style={{ height: '80vh', overflowY: 'scroll', width: '45vh', backgroundColor: 'rgb(41, 50, 63)', border: '0px' }}>

        <Input
          type="text"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Search Endpoints"
          style={{backgroundColor: 'rgb(41, 50, 63)', border: '0', color: '#ffffff'}}
        />

        {
          endpointsLu.map(endpoint => {
            if (!filterEndpoints(endpoint)) return null

            return (
              <DropdownItem
                key={endpoint.route}
                onClick={() => setExplorerEndpoint(endpoint)}
                className="swish-dropdown-item"
              >
                { endpoint.route }
              </DropdownItem>
            )
          })
        }
      </DropdownMenu>
    </Dropdown>
  )
}

export default EndpointDropdown
