import React, { useState, useEffect } from 'react'

import { Table, UncontrolledPopover, PopoverBody } from 'reactstrap'

import {
  Row
} from 'simple-flexbox'

import Help from '@material-ui/icons/Help'

import {
  orderBy
} from 'lodash'

import {
  returnSpawnApiUrl
} from '../../helpers/urls'

import {
  formatDescriptionsForBool
} from '../../helpers/general'


const ParamItem = ({ param }) => {
  return (
    <div
      className="pad-10 pad-left-0 bg-110"
      style={{ borderBottom: '1px solid rgba(223, 224, 235, 0.2)' }}
    >
      <div>
        <span
          style={{ fontWeight: 'bold' }}
        >
          { param.paramName }
        </span>
        {
          param.minRequired && !param.required ?
            (
              <span className="text-muted pad-left-10 text-right">
                Soft Required
                {' '}
                <>
                  <Help
                    className="mar-left-5 pointer"
                    style={{ height: '15px', width: '15px', outline: 'none' }}
                    id="required"
                  />
                  <UncontrolledPopover
                    trigger="focus"
                    placement="top"
                    className="pad-10"
                    target="required"
                  >
                    <PopoverBody>
                      For parameters that are marked as <code>soft</code> required,
                      that means at least one of them must be included with requests to the API.
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              </span>
            )
            : null
        }
        {
          param.required
            ? (
              <span className="text-muted pad-left-10 text-right">
                Required
              </span>
            ) : null
        }
      </div>

      <div>
        <div
          className="mar-top-5 sm-95"
          dangerouslySetInnerHTML={{ __html: formatDescriptionsForBool(param.desc) }}
        />
      </div>
      <div className="mar-top-5 sm-95">
        Example: <code>{param.example}</code>
      </div>
    </div>
  )
}

const filterTable = (minRequired, param) => {
  if (minRequired === undefined) return true

  if (parseFloat(minRequired) === param.minRequired) {
    return true
  }

  return false
}

const ParametersTable = ({ endpoint, minRequired }) => {
  const [ params, setParams ] = useState([])
  const [ showMinRequiredHelp, setShowMinRequiredHelp ] = useState(false)

  useEffect(() => {
    if (params.length === 0) {
      fetch(`${ returnSpawnApiUrl() }/documentation/params/${ endpoint }`)
        .then(res => res.json())
        .then(res => {
          if (res.data.status && res.data.results) {
            setParams(res.data.results)
            setShowMinRequiredHelp(res.data.results.some(r => r.minRequired))
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [])


  if (params.length === 0) return null

  return (
    <Table>
      <tbody>
        {
          orderBy(params, [ 'minRequired' ], [ 'desc' ]).map(param => {
            if (!filterTable(minRequired, param)) return null

            return (
              <ParamItem
                key={param.paramName}
                param={param}
              />
            )
          })
        }
      </tbody>
    </Table>
  )
}

export default ParametersTable
