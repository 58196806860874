import React, { useState, useEffect } from 'react'

import {
  ParamInput
} from './input-types'

import {
  loadSearch
} from '../../../helpers/search'

const findExplorerSport = endpoint => {
  if (!endpoint) return null

  return [ 'mlb', 'nfl', 'nba', 'nhl' ].find(s => (
    endpoint.route.indexOf(s) >= 0))
}

const mergeMarketsAndReferences = (refs, markets) => {
  const lookup = refs.reduce((a,c) => {
    if (!a[ c.referenceGroup ]) {
      a[ c.referenceGroup ] = []
    }

    a[ c.referenceGroup ].push({
      name: c.referenceName, id: c.referenceId, sport: c.sport
    })

    return a
  }, {})

  lookup.stat = []

  markets.forEach(m => {
    lookup.stat.push({
      name: m.statName, id: m.statTypeId, sport: m.sport.toLowerCase()
    })
  })

  return lookup
}

const ParamsContainer = ({
  explorerEndpoint,
  explorerParams,
  setExplorerParams
}) => {
  const [ references, setReferences ] = useState([])
  const [ sport, setSport ] = useState(findExplorerSport(explorerEndpoint))

  useEffect(() => {
    loadSearch((err, res) => {
      if (!err) {
        setReferences(mergeMarketsAndReferences(res.values, res.markets))
      }
    })
  }, [])

  useEffect(() => {
    setSport(findExplorerSport(explorerEndpoint))
  }, [ explorerEndpoint ])

  if (!explorerEndpoint) return null

  const prepareExplorerParams = (param, value) => {
    const explorerParamsCopy = { ...explorerParams }

    if (explorerParamsCopy[ param ] && !value) {
      delete explorerParamsCopy[ param ]
    }

    if (value) explorerParamsCopy[ param ] = value

    setExplorerParams(explorerParamsCopy)
  }

  return (
    <div className="mar-top-20" style={{ height: '80%', overflowY: 'scroll' }}>
      {
        explorerEndpoint.params.map(param => {
          return (
            <ParamInput
              key={param.paramName}
              param={param}
              prepareExplorerParams={prepareExplorerParams}
              references={references}
              sport={sport}
              route={explorerEndpoint.route}
            />
          )
        })
      }
    </div>
  )
}

export default ParamsContainer
