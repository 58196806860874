import React, { useState } from 'react'

import {
  Card,
  CardBody
} from 'reactstrap'

import Check from '@material-ui/icons/Check'

import {
  Column,
  Row
} from 'simple-flexbox'

import GameSelection from './game-selection-dropdown'

const GameSelectionCard = ({
  selectGame, selectedGame, games, replayStatus
}) => (
  <Card
    style={
      {
        borderRadius: '8px',
        backgroundColor: '#374150',
        color: '#fff'
      }
    }
  >
    <CardBody className="pad-0">
      <Row>
        <Column className="text-left">
          <GameSelection
            selectGame={s => selectGame(s)}
            selectedGame={selectedGame}
            games={games}
            replayStatus={replayStatus}
          />
        </Column>
      </Row>
    </CardBody>
  </Card>
)

export default GameSelectionCard
