/* eslint-disable react/prop-types */
import React from 'react'

import { useHistory } from 'react-router-dom'

import { scroller } from 'react-scroll'

import {
  Table
} from 'reactstrap'

import {
  truncateString,
  removeHeaderTags
} from '../../../helpers/general'

// eslint-disable-next-line no-unused-vars
const FaqItem = ({ faq, onSelect, search }) => (
  <tr
    className="pad-10 search-item"
    onClick={onSelect}
  >
    <td className="width-2" />
    <td className="width-98">
      <div className="bg-130">
        { faq.question }
      </div>
      <div className="sm-75">
        <span
          dangerouslySetInnerHTML={
            { __html: truncateString(removeHeaderTags(faq.answer)) }
          }
        />
      </div>
    </td>
  </tr>
)

const goToFaq = (history, scrollTo) => {
  history.push(`/faq/${ scrollTo }`)

  scroller.scrollTo(scrollTo, {
    duration: 750,
    delay: 25,
    smooth: true
  })
}

const FAQs = ({
  faqs, onSelect, count, search
}) => {
  const history = useHistory()

  return (
    <div>
      <div
        className="pad-10 bg-130 text-center klavika-regular"
      >
        <b>FAQs</b>
      </div>

      <Table size="sm" borderless>
        <tbody>
          {
            faqs.slice(0, count).map(f => (
              <FaqItem
                key={f.id}
                faq={f}
                onSelect={() => {
                  goToFaq(history, f.id)
                  onSelect()
                }}
                search={search}
              />
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default FAQs
