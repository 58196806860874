import React, { useState } from 'react'
import { Row, Column } from 'simple-flexbox'
import AspectRatioRoundedIcon from '@material-ui/icons/AspectRatioRounded'
import {
  Card,
  CardBody,
  CardTitle
} from 'reactstrap'

import Highlight from 'react-highlight'

import DocResponseModal from './response-modal'

const ResponseWindow = ({ children, title }) => {
  const [ modalIsOpen, toggleModalOpen ] = useState(false)

  const toggle = () => toggleModalOpen(!modalIsOpen)


  return (
    <>
      <DocResponseModal
        response={children}
        isOpen={modalIsOpen}
        toggle={toggle}
      />
      <Card
        style={{
          display: 'block',
          background: '#29323F',
          borderRadius: '0.25rem',
          border: 'none'
        }}
        className="pad-0"
      >
        <CardTitle
          style={{ borderBottom: '1px solid #8894a8' }}
          className="pad-10"
        >
          <Row>
            <Column flexGrow={11} className="bg-130 pad-left-10 klavika-regular">
              <b>{ title || 'Response' }</b>
            </Column>
            <Column flexGrow={1} className="text-right">
              <AspectRatioRoundedIcon
                onClick={toggle}
                style={{ cursor: 'pointer' }}
              />
            </Column>
          </Row>
        </CardTitle>
        <CardBody
          className="pad-top-5"
          style={{ maxHeight: '320px', overflowX: 'scroll' }}
        >
          <Highlight
            className="json"
            style={{ maxHeight: '310px', background: '#1d1f21' }}
          >
            {children}
          </Highlight>
        </CardBody>
      </Card>
    </>
  )
}

export default ResponseWindow
