import React, { useState } from 'react'

import {
  Button, Input,
  Modal, ModalBody, ModalHeader,
  ModalFooter
} from 'reactstrap'

import {
  toTitleCase
} from '../../../helpers/general'

const LookupModal = ({
  isOpen,
  toggle,
  lookup,
  param,
  selectReference
}) => {

  const [ search, setSearch ] = useState('')

  if (!lookup) return null

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="lookup-modal rounded"
      style={{}}
    >
      <ModalHeader toggle={toggle}>{ param }</ModalHeader>
      {
        lookup.length >= 10
          ? (
            <ModalHeader>
              <Input
                className="input-parameter white"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onFocus={e => e.target.select()}
                placeholder="Search"
              />
            </ModalHeader>
          ) : null
      }
      <ModalBody style={{ overflowY: 'scroll', height: '60vh'}}>
        {
          lookup.map(l => {
            if (l.name.indexOf(search.toLowerCase()) === -1) return null

            return (
              <div
                key={l.name + l.id}
                className="lookup-item pad-10"
                onClick={() => (
                  selectReference(l.id || l.name
                  ))}
                onKeyUp={() => {}}
              >
                {toTitleCase(l.name)}
              </div>
            )
          })
        }
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => selectReference('')}
          color="danger"
          className="width-100 rounded"
        >
          Clear
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default LookupModal
