import React from 'react'

const TagItem = ({ tag, filters, updateFilters }) => {
  const isActive = filters.find(f => f.type === 'tag' && f.name === tag)

  if (!tag) return null

  return (
    <span
    className="pad-5 pad-left-10 pad-right-10 rounded pointer mar-right-10 sm-90"
    style={
      {
        border: isActive ? '1px solid #ff479c' : '1px solid #CDDCEF',
        color: isActive ? '#ff479c' : '#CDDCEF',
      }
      }
      onClick={() => updateFilters({name: tag, type: 'tag'})}
    >
      <span className="enhanced-sports enhanced-sports-tag4" />
      {' '}
      { tag }
    </span>
  )
}

export default TagItem
