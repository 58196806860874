import React from 'react'

import { Column, Row } from 'simple-flexbox'

import moment from 'moment'

const Footer = () => {
  const year = moment().format('YYYY')

  return (
    <div>
      <div className="pad-30">
        <br/><br/><br/><br/><br/><br/>
      </div>

      <div className="footer-fixed">
        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">
          <Column flex="1" className="pad-20 text-center">

            <div className="white bg-120">
              <img
                src="https://swish-assets.s3.us-west-2.amazonaws.com/imgs/business/sportsbook/Swish-Planet-2023-White-Transparent.png"
                height="25"
                width="25"
                alt="Swish"
                className="pad-5 mar-top-neg-3 inline"
              />
            Swish Analytics Inc | { year }
            </div>
          </Column>
        </Row>
      </div>
    </div>
  )
}

export default Footer
