import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row
} from 'reactstrap'

import {
  changePW
} from '../../helpers/auth'

export class ChangePW extends Component {
  constructor (props) {
    super(props)

    this.updatePW = this.updatePW.bind(this)

    this.state = {
      changeError: '',
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      redirect: false,
      passwordChanged: false,
      requesting: false
    }
  }

  componentDidUpdate () {
    const { passwordChanged } = this.state

    if (passwordChanged) {
      this.id = setTimeout(() => {
        this.setState({ redirect: true })
      }, 2000)
    }
  }

  componentWillUnmount () {
    clearTimeout(this.id)
  }


  updatePW (e) {
    if (e) {
      e.preventDefault()
    }

    const {
      oldPassword,
      newPassword,
      newPassword2
    } = this.state

    if (
      oldPassword === ''
      || oldPassword.length < 8
      || newPassword === ''
      || newPassword.length < 8
      || newPassword2 === ''
      || newPassword2.length < 8
    ) {

      return this.setState({
        changeError: 'Please submit a valid password.',
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      })
    }

    if (newPassword !== newPassword2) {

      return this.setState({
        changeError: 'New passwords do not match',
        newPassword: '',
        newPassword2: ''
      })
    }


    this.setState({
      requesting: true
    })

    changePW({ oldPassword, newPassword, newPassword2 }, err => {
      if (err) {
        this.setState({
          changeError: `Enter your old password, as well as the
          password that you would like to update
          your account with.`,
          newPassword: '',
          newPassword2: '',
          requesting: false
        })
      } else {
        this.setState({
          passwordChanged: true,
          requesting: false
        })
      }
    })
  }

  render () {
    const {
      changeError,
      oldPassword,
      newPassword,
      newPassword2,
      passwordChanged,
      redirect,
      requesting
    } = this.state

    return (
      <div className="app flex-row">
        {
          redirect
            ? <Redirect to="/account" /> : null
        }
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="p-4 light-dark-back white bord-none">
                <CardBody>
                  <Form>
                    <div>
                      { !passwordChanged
                        ? <h1 className="klavika-regular"><b>Change Password</b></h1>
                        : <h1 className="klavika-regular">Successfully updated</h1> }
                      <p className="text-muted">
                        { changeError }
                      </p>
                      {
                        !passwordChanged
                          ? (
                            <div>
                              <InputGroup className="mb-3">
                                <Input
                                  className="rounded"
                                  type="password"
                                  placeholder="Old Password"
                                  id="oldPassword"
                                  style={{
                                    backgroundColor: 'rgb(41, 50, 63)', border: '0', color: '#ffffff'
                                  }}
                                  value={oldPassword}
                                  onChange={
                                    e =>
                                      this.setState({
                                        oldPassword: e.target.value
                                      })
                                  }
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <Input
                                  className="rounded"
                                  type="password"
                                  placeholder="New Password"
                                  id="newPassword"
                                  style={{
                                    backgroundColor: 'rgb(41, 50, 63)', border: '0', color: '#ffffff'
                                  }}
                                  value={newPassword}
                                  onChange={
                                    e =>
                                      this.setState({
                                        newPassword: e.target.value
                                      })
                                  }
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <Input
                                  className="rounded"
                                  type="password"
                                  placeholder="New Password Again"
                                  id="newPassword2"
                                  style={{
                                    backgroundColor: 'rgb(41, 50, 63)', border: '0', color: '#ffffff'
                                  }}
                                  value={newPassword2}
                                  onChange={
                                    e =>
                                      this.setState({
                                        newPassword2: e.target.value
                                      })
                                  }
                                />
                              </InputGroup>
                              <Row>
                                <Col>
                                  {
                                    !requesting ?
                                      (
                                        <Button
                                          id="sendEmail"
                                          className="sport-endpoint-button bg-120 klavika-regular"
                                          onClick={this.updatePW}
                                        >
                                        <b>UPDATE PASSWORD</b>
                                        </Button>
                                      )
                                      :
                                      (
                                        <Button
                                          className="px-4 sport-endpoint-button"
                                          disabled
                                        >
                                        Loading...
                                        </Button>
                                      )
                                  }
                                </Col>
                              </Row>
                            </div>
                          ) :
                          (
                            <div>
                              <p>
                              Your password has been updated.
                              Redirecting...
                              </p>
                            </div>
                          )
                      }
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default ChangePW
