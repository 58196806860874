import React, { useState, useEffect } from 'react'

import {
  sum
} from 'lodash'

import { loadSearch } from '../../helpers/search'

import {
  Docs,
  FAQs,
  MarketsContainer,
  ReferenceContainer
} from './types'

const runSearch = (searchFrom, searchFor) => {
  if (!searchFor || !searchFrom) return true

  return searchFor.split(' ').every(v => (
    searchFrom.toLowerCase().indexOf(v.toLowerCase()) >= 0
  ))
}

const SearchResults = ({ search, onSelect }) => {
  const [ data, setData ] = useState(null)

  useEffect(() => {
    if (!data) {
      loadSearch((err, res) => {
        if (!err) setData(res)
      })
    }
  }, [])

  const docs = data
    ? data.docs.filter(d => runSearch(`${ d.title } ${ d.tags }`, search)) : []

  const values = data
    ? data.values.filter(d => (
      runSearch(`
        ${ d.referenceName }
        ${ d.referenceGroup }
        ${ d.referenceName }
        ${ d.sport }
        ${ d.description }`, search)
    )) : []

  const markets = data
    ? data.markets.filter(d => runSearch(d.statName, search)) : []

  const faqs = data ? data.faqs.filter(f => (
    runSearch(` ${ f.question } ${ f.answer }`, search)
  )) : []

  if (search === '') {
    return (
      <div className="search-results-container">
        <div className="sm-85 text-muted pad-10">
          Search Docs, Markets, References, FAQs etc.
        </div>
      </div>
    )
  }

  const sectionCount = 24 / sum([
    docs.length > 0 ? 1 : 0,
    values.length > 0 ? 1 : 0,
    markets.length > 0 ? 1 : 0,
    faqs.length > 0 ? 1 : 0
  ])

  return (
    <div
      className="search-results-container"
    >
      {
        docs.length > 0
          ? (
            <Docs
              docs={docs}
              onSelect={onSelect}
              count={sectionCount}
              search={search}
            />
          )
          : null
      }

      {
        markets.length > 0
          ? (
            <MarketsContainer
              markets={markets}
              onSelect={onSelect}
              count={sectionCount}
              search={search}
            />
          )
          : null
      }

      {
        values.length > 0
          ? (
            <ReferenceContainer
              references={values}
              onSelect={onSelect}
              count={sectionCount}
              search={search}
            />
          )
          : null
      }

      {
        faqs.length > 0
          ? (
            <FAQs
              faqs={faqs}
              onSelect={onSelect}
              count={sectionCount}
              search={search}
            />
          )
          : null
      }

      {
        (
          docs.length === 0
          && markets.length === 0
          && values.length === 0
          && faqs.length === 0
        )
          ? (
            <div className="sm-85 text-muted pad-10">
              No Results
            </div>
          )
          : null
      }
    </div>
  )
}

export default SearchResults
