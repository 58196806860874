import React, { useState, useEffect } from 'react'

import {
  Card,
  CardBody
} from 'reactstrap'

import {
  Row
} from 'simple-flexbox'

import TagItem from './tag-item'
import SportsSection from './sports-section'
import EndpointSection from './endpoints-section'
import EndpointsList from './endpoints-list'

const FAQItem = ({ faq, filters, updateFilters }) => {
  const [ cardOpen, setCardOpen ] = useState(false)
  const [ showEndpoints, setShowEndpoints ] = useState(false)

  const toggleShowEndpoints = () => {
    setShowEndpoints(!showEndpoints)
  }


  return (
    <Card
      className="rounded"
      style={{ backgroundColor: '#29323F' }}
      onClick={() => setCardOpen(!cardOpen)}
    >
      <CardBody className="white">
        <Row>
          <div className="bg-150">
            <b dangerouslySetInnerHTML={{ __html: faq.question }} />
          </div>
        </Row>
        <Row className="mar-top-10 bg-120">
          <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </Row>
        <Row className="mar-top-20">
          <EndpointSection
            endpoints={faq.endpoints ? faq.endpoints.split(',') : []}
            toggleShowEndpoints={toggleShowEndpoints}
            showEndpoints={showEndpoints}
          />
          {
            faq.type.split(',').map(type => {
              return (
                <TagItem
                  filters={filters}
                  updateFilters={updateFilters}
                  key={type}
                  tag={type}
                />
              )
            })
          }
          <SportsSection
            sports={faq.sports}
            updateFilters={updateFilters}
            filters={filters}
          />
        </Row>
        {
          showEndpoints
            ? (
              <Row>
                <EndpointsList
                  endpoints={faq.endpoints ? faq.endpoints.split(',') : []}
                />
              </Row>
            ) : null
        }
      </CardBody>
    </Card>
  )
}

export default FAQItem
