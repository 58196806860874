import React, { useState, useEffect, useRef } from 'react'

const FixedRightSideColumn = ({ children }) => {
  const ref = useRef(null)
  const [ fixColumn, setFixColumn ] = useState(false)

  const onScroll = () => {
    if (window.pageYOffset >= 110) {
      setFixColumn(true)
    } else {
      setFixColumn(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [ window.pageYOffset ])


  return (
    <div
      className="doc-right-nav"
      style={{
        top: fixColumn ? '-10px' : '190px',
        position: fixColumn ? 'fixed' : 'absolute'
      }}
    >
      { children }
    </div>
  )
}

export default FixedRightSideColumn
