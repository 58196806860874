import React from 'react'

import { useHistory } from 'react-router-dom'

import MenuItem from '../sidebar-menuitem'


import { useGuide } from '../../../context/guide-context'

const GuideSideBar = () => {
  const history = useHistory()
  const { currentSection, guideSections, updateCurrentSection } = useGuide()


  const setActive = (g, section) => {
    if ((
      !section && g === 'introduction')
      || (section && g === section.getAttribute('name')
      )) {
      return true
    }

    return false
  }

  return (
    <>
      <MenuItem
        title="Home"
        onClick={() => history.push('/')}
        bold
      />
      {
        guideSections.map(g => {
          return (
            <MenuItem
              key={g}
              title={g.replace('guide-section-', '').split('-').join(' ')}
              onClick={() => updateCurrentSection(g)}
              active={setActive(g, currentSection)}
              bold
            />
          )
        })
      }
    </>
  )
}

export default GuideSideBar
