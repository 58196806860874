import React from 'react'

import { useHistory } from 'react-router-dom'

import MenuItem from '../sidebar-menuitem'


const MainSideBar = ({ pathname }) => {
  const history = useHistory()

  return (
    <div className="mar-top-60">
      <MenuItem
        title="Home"
        active={pathname === '/'}
        onClick={() => history.push('/')}
        bold
      />
      <MenuItem
        title="Documentation"
        onClick={() => history.push('/docs-landing')}
        active={pathname === '/docs-landing'}
        bold
      />
      <MenuItem
        title="Explorer"
        onClick={() => history.push('/explorer')}
        active={pathname === '/explorer'}
        bold
      />
      <MenuItem
        title="Replay"
        onClick={() => history.push('/replay')}
        active={pathname.indexOf('/replay') >= 0}
        bold
      />
      <MenuItem
        title="Reference"
        onClick={() => history.push('/reference')}
        active={pathname.indexOf('/reference') >= 0}
        bold
      />
      <MenuItem
        title="Market Coverage"
        onClick={() => history.push('/markets')}
        active={pathname.indexOf('/markets') >= 0}
        bold
      />
      <MenuItem
        title="FAQ"
        onClick={() => history.push('/faq')}
        active={pathname.indexOf('/faq') >= 0}
        bold
      />
    </div>
  )
}

export default MainSideBar
