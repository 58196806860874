import React, { useState } from 'react'

import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'

const TypeDropdown = ({
  requestedType, setRequestedType, referenceGroups, sport
}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)


  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret className="sport-endpoint-dropdown"
      style={{ padding: '10px', width:'60%'}}>
        {
          requestedType || 'Pick a type'
        }
      </DropdownToggle>
      <DropdownMenu
      className="rounded width-70"
      style={{ backgroundColor: 'rgb(41, 50, 63)', border: '0px' }}
      >
        {
          referenceGroups.map(ref => {
            if (ref.sport.indexOf(sport) === -1) return null

            return (
              <DropdownItem
                className="swish-dropdown-item pad-left-10"
                key={ref.referenceGroup + ref.sport}
                onClick={() => setRequestedType(ref.referenceGroup)}
              >
                {ref.referenceGroup}
              </DropdownItem>
            )
          })
        }
      </DropdownMenu>
    </Dropdown>
  )
}

export default TypeDropdown
