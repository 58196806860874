import React from 'react'

import {
  Input
} from 'reactstrap'

const ReferenceSearchBar = ({ search, setSearch, placeholder }) => {
  return (
    <Input
      className="width-90 search-bar-faqs"
      value={search}
      onChange={e => setSearch(e.target.value)}
      placeholder={placeholder || 'Search for Types'}
    />
  )
}

export default ReferenceSearchBar
