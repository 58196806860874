import React from 'react'

import {
  formatTeamNickname
} from '../../../helpers/general'

import {
  returnFormattedQuarter,
  formatSeconds,
  orindal,
  splitYardLine
} from '../../../helpers/replay'

const NFLGameState = ({ currentGamestate }) => {

  if (!currentGamestate) return null

  return (
    <table className="width-100 helvetica" style={{height: '50%'}}>
      <tbody>
        <tr>
          <td className="text-left pad-5 width-10">
            <img
              alt="img"
              height="23px"
              className="pad-left-5"
              src={`https://swish-assets.s3-us-west-2.amazonaws.com/imgs/nfl/teams/${formatTeamNickname(currentGamestate.general.awayTeamNickname)}.png`}
            />
            {' '}
            {currentGamestate.general.awayTeamNickname}
            {
              currentGamestate.gamestate.possTeamId === currentGamestate.general.awayTeamId ?
              <>{' '}<span className="enhanced-sports enhanced-sports-football" /></> : null
            }
          </td>
          <td className="text-center pad-5 width-5 bord-right-grey">
            <b className="">{currentGamestate.gamestate.awayTeamScore}</b>
          </td>
          <td className="text-center pad-5 width-15 bord-right-grey">
            <b className="">{currentGamestate.gamestate.clockMin}:{formatSeconds(currentGamestate.gamestate.clockSec)}</b>
          </td>
          <td className="text-center pad-5 width-15">
            <b className="">{orindal(currentGamestate.gamestate.down)} & {currentGamestate.gamestate.distance}</b>
          </td>
          <td className="text-left pad-5 width-25 bord-right-grey">
            <b>Last Play:</b> { currentGamestate.gamestate.lastPlay }
          </td>
        </tr>

        <tr className="bord-top-grey">
          <td className="text-left pad-5 width-10">
            <img
              alt="img"
              height="23px"
              className="pad-left-5"
              src={`https://swish-assets.s3-us-west-2.amazonaws.com/imgs/nfl/teams/${formatTeamNickname(currentGamestate.general.homeTeamNickname)}.png`}
            />
            {' '}
            {currentGamestate.general.homeTeamNickname}
            {
              currentGamestate.gamestate.possTeamId === currentGamestate.general.homeTeamId ?
              <>{' '}<span className="enhanced-sports enhanced-sports-football" /></> : null
            }
          </td>
          <td className="text-center pad-5 width-5 bord-right-grey">
            <b className="">{currentGamestate.gamestate.homeTeamScore}</b>
          </td>
          <td className="text-center pad-5 width-5 bord-right-grey">
            <b className="">{returnFormattedQuarter(currentGamestate.gamestate.quarter)}</b>
          </td>
          <td className="text-center pad-5 width-5 bord-right-grey">
            <b className="">{splitYardLine(currentGamestate.gamestate.yardline)}</b>
          </td>
        </tr>

      </tbody>
      </table>
  )
}

export default NFLGameState
