import React from 'react'

import {
  Button
} from 'reactstrap'

import { useAuth } from '../../context/auth-context'

const LogoutButton = () => {
  const { logout } = useAuth()

  return (
    <Button
      className="logout-button bg-120 klavika-regular"
      onClick={logout}
    >
      <b>LOG OUT</b>
    </Button>
  )
}

export default LogoutButton
