import React, { useState, useEffect } from 'react'
import { Column, Row } from 'simple-flexbox'
import { StyleSheet, css } from 'aphrodite'
import Sidebar from '../sidebar/sidebar'
import Header from '../header/header'
import Footer from '../footer'
import { useAuth } from '../../context/auth-context'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    minHeight: '100vh'
  },

  content: {
    marginTop: 54
  },
  mainBlock: {
    backgroundColor: '#374150',
    marginLeft: '210px',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    paddingTop: 15,
    '@media (max-width: 768px)': {
      marginLeft: 0
    }
  }
})

const getWidth = () => window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth

const checkMobile = () => getWidth() <= 768

const Dashboard = props => {
  const { validateAccount } = useAuth()
  const { children } = props
  const [ expanded, setExpanded ] = useState(!checkMobile())
  const [ isMobile, setIsMobile ] = useState(checkMobile())
  const [ selectedItem, setSelectedItem ] = useState('Dashboard')

  const handleClick = (item, exp) => {
    setExpanded(exp)
    setSelectedItem(item)
  }

  const handleToggle = exp => {
    setExpanded(exp)
  }

  useEffect(() => {
    const resizeHandler = () => {

      if (checkMobile()) {
        setIsMobile(true)
        setExpanded(false)
      } else {
        setIsMobile(false)
        setExpanded(true)
      }
    }

    // set resize listener
    window.addEventListener('resize', resizeHandler)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    validateAccount()
  }, [ props.location.pathname ])

  return (
    <Row className={css(styles.container)}>

      <Sidebar
        expanded={expanded}
        isMobile={isMobile}
        selectedItem={selectedItem}
        onClick={handleClick}
        onToggle={handleToggle}
        pathname={props.location.pathname}
      />
      <Column flexGrow={1} className={css(styles.mainBlock)}>
        <Header title={selectedItem} />
        <div className={css(styles.content)}>
          { children }
        </div>
        <Footer />
      </Column>
    </Row>
  )
}

export default Dashboard
