import React from 'react'
import {
  Modal, ModalHeader, ModalBody
} from 'reactstrap'


import RoleButtons from './role-buttons'
import RevokeKey from './revoke-key'
import RemoveMember from './remove-member'


const SettingsModal = ({ member, toggle, isOpen }) => {

  if (!isOpen) return null

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      style={{
        maxWidth: '600px',
        width: '70%',
        borderRadius: '10px',
        backgroundColor: '#374150',
        color: '#fff'
      }}
    >
      <ModalHeader
        toggle={toggle}
        className="klavika-regular pad-30 pad-top-15 pad-bottom-15"
      >
        <b className="bg-140">
          {
            member
              ? `Update access for ${ member.name }`
              : null
          }
        </b>
      </ModalHeader>
      <ModalBody style={{
        borderRadius: '8px',
        padding: '30px'
      }}
      >
        <RoleButtons
          member={member}
          toggle={() => toggle(member)}
        />

        <RevokeKey
          member={member}
          toggle={() => toggle(member)}
        />

        <RemoveMember
          member={member}
          toggle={() => toggle(member)}
        />

      </ModalBody>
    </Modal>
  )
}

export default SettingsModal
