import React, { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'js-cookie'
import Loading from '../Views/loading'

import {
  loadAccount
} from '../helpers/account'

import {
  logoutClient,
  loginClient,
  registerClient,
  sendResetEmail,
  resetPWClient,
  setPWClient,
  validateAccountStatus
} from '../helpers/auth'

const version = process.env.REACT_APP_VERSION

const init = cb => {

  loadAccount((accountErr, account) => {
    if (accountErr) return cb(true)

    if (!accountErr) {
      datadogRum.addRumGlobalContext('email', account.email)

      return cb(null, account)
    }
  })
}

const AuthContext = React.createContext()

const AuthProvider = props => {
  const [ requesting, setRequesting ] = useState(true)
  const [ accountRequested, setAccountRequested ] = useState(false)
  const [ data, setData ] = useState({
    account: false,
    loggedIn: Cookies.get(`loggedIn${ version === 'v2' ? '-v2' : '' }`)
  })

  useEffect(() => {
    if (data.loggedIn && !data.account && !accountRequested) {

      setRequesting(true)
      setAccountRequested(true)

      init((accountErr, account) => {
        if (accountErr) {
          setData({
            account: false,
            loggedIn: false
          })
        }

        if (account) {
          data.account = account
          setData({
            ...data,
            account
          })
        }

        setRequesting(false)
      })
    } else {
      setRequesting(false)
    }
  }, [ data, requesting ])

  if (requesting) {
    return <Loading />
  }

  const setUserData = cb => {
    loadAccount((accountErr, account) => {
      cb(accountErr)
      if (!accountErr) {
        cb(null)

        data.account = account

        setData({
          ...data,
          account,
          loggedIn: Cookies.get(`loggedIn${ version === 'v2' ? '-v2' : '' }`)
        })
      }
    })
  }

  const forgotPW = (email, cb) => {
    sendResetEmail(email, loginErr => {

      if (loginErr) return cb(loginErr.name)

      cb(null)

    })
  }

  const resetPW = (user, cb) => {
    resetPWClient(user, loginErr => {

      if (loginErr) return cb(loginErr.name)

      cb(null)

    })
  }

  const setPW = (user, cb) => {
    setPWClient(user, loginErr => {

      if (loginErr) return cb(loginErr.name)

      cb(null)

    })
  }

  const login = (user, cb) => {
    loginClient(user, loginErr => {

      if (loginErr) return cb(loginErr.name)

      setUserData(cb)
    })
  }

  const logout = () => {
    logoutClient(() => {
      setData({
        ...data,
        account: false,
        loggedIn: Cookies.get(`loggedIn${ version === 'v2' ? '-v2' : '' }`)
      })
    })
  }

  const register = (user, cb) => {
    registerClient(user, registrationError => {

      if (registrationError) return cb(registrationError.name)

      setUserData(cb)
    })
  }

  const validateAccount = () => {
    validateAccountStatus((err, res) => {
      if (err || !res) {
        logout()
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        data, login, logout, register, forgotPW, resetPW, setPW, validateAccount
      }}
      {...props}
    />
  )
}

const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {

    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export {
  AuthProvider,
  AuthContext,
  useAuth
}
