import React from 'react'

import {
  Row,
  Column
} from 'simple-flexbox'

const returnSportIcon = sport => {
  if (sport === '*') return ''

  if (sport === 'nba' || sport === 'cbb') {
    return 'enhanced-sports enhanced-sports-basketball'
  }

  if (sport === 'nfl' || sport === 'cfb') {
    return 'enhanced-sports enhanced-sports-football'
  }

  if (sport === 'nhl') return 'enhanced-sports enhanced-sports-hockey'

  if (sport === 'mlb') return 'enhanced-sports enhanced-sports-baseball'
}

const SportItemNav = ({ filter, updateFilters }) => (
  <Row
    className="pad-5 pad-left-10 pad-right-10 rounded pointer mar-right-10 sm-90"
    style={{ border: '1px solid #CDDCEF', color: '#CDDCEF' }}
    onClick={() => updateFilters(filter)}
  >
    <Column flex="10">
      <div>
        <span className={returnSportIcon(filter.name.toLowerCase())} />
        {' '}
        {filter.name === '*' ? 'All Sports' : filter.name.toUpperCase()}
      </div>
    </Column>

    <Column flex="2">
      <span className="text-right enhanced-sports enhanced-sports-close3" />
    </Column>
  </Row>
)

export default SportItemNav
