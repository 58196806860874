import React, { useState, useEffect } from 'react'

import { Table } from 'reactstrap'

const TableHeader = ({ headers }) => (
  <thead>
    <tr>
      {
        headers.map(h => {
          return <th key={h} className="bord-none">{h}</th>
        })
      }
    </tr>
  </thead>
)

const CustomTable = ({ headers, content }) => {
  return (
    <Table size="sm">
      <TableHeader headers={eval(headers)} />
      <tbody>
        {
          eval(content).map(row => {
            return (
              <tr key={row}>
                {
                  row.map(column => {
                    return (
                      <td key={column} className="bord-none pad-5">{column}</td>
                    )
                  })
                }
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  )
}

export default CustomTable
