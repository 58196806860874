import React from 'react'

import {
  Input
} from 'reactstrap'

import {
  Row,
  Column
} from 'simple-flexbox'

import IconSearch from '../../../assets/icon-search'


import TagItemNav from './tag-item-nav'
import SportItemNav from './sport-item-nav'

const FAQNavBar = ({ search, setSearch, filters=[], updateFilters}) => {
  return (
    <Row
      flexGrow={1}
      style={{ margin: '20px 10px' }}
      className="pad-top-15 pad-bottom-0"
    >
      <Column className="pad-top-10">
        <IconSearch />
      </Column>
      <Column
        flex="8"
        className="bord-bottom-grey"
        style={{ height: '35px' }}
      >
        <Input
          className="search-bar-faqs"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={'Search FAQs'}
        />
      </Column>
      <Column flex="3" offset="1" className="pad-10">
        {
          filters.map(filter => {

            if (filter.type === 'tag') {
              return (
                <div className="mar-5" style={{ display: 'inline-block' }}>
                  <TagItemNav
                    key={filter.name + filter.type}
                    filter={filter}
                    updateFilters={updateFilters}
                    className="width-80"
                  />
                </div>
              )
            }

            if (filter.type === 'sports') {
              return (
                <div className="mar-5" style={{ display: 'inline-block' }}>
                  <SportItemNav
                    key={filter.name + filter.type}
                    filter={filter}
                    updateFilters={updateFilters}
                    className="width-80"
                  />
                </div>
              )
            }

            return null
          })
        }
      </Column>
    </Row>
  )
}


export default FAQNavBar
