import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ReactJson from 'react-json-view'

const ResponseModal = ({ response, isOpen, toggle }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={'lg'}
      style={{
        maxWidth: '1000px',
        width: '70%',
        borderRadius: '10px',
        backgroundColor: '#29323F',
        color: '#fff'
      }}
    >
      <ModalHeader toggle={toggle} className="bg-130 pad-20 pad-top-15 pad-bottom-15 klavika-regular"><b>API Response</b></ModalHeader>
      <ModalBody style={{ overflowY: 'scroll', height: '80vh', borderRadius: '8px' }}>
        {
          response ?
            (
              <ReactJson
                src={response}
                name={false}
                collapseStringsAfterLength={20}
                groupArraysAfterLength={3}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                theme={{
                  base00: '#29323F',
                  base01: '#29323F',
                  base02: '#29323F',
                  base03: '#29323F',
                  base04: '#fff',
                  base05: '#fff',
                  base06: '#fff',
                  base07: '#fff',
                  base08: '#fff',
                  base09: '#0ec8f8',
                  base0A: '#fff',
                  base0B: '#0ec8f8',
                  base0C: '#fff',
                  base0D: '#fff',
                  base0E: '#E83E85',
                  base0F: '#11ce00'
                }}
              />
            )
            : 'Make a request to see a response!'
        }
      </ModalBody>
    </Modal>
  )
}

export default ResponseModal
