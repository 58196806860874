import React, { useState, useEffect } from 'react'

import { Column, Row } from 'simple-flexbox'
import Help from '@material-ui/icons/Help'
import Search from '@material-ui/icons/Search'


import { isFirefox } from 'react-device-detect'

import {
  Input, InputGroup, UncontrolledPopover, PopoverBody, Button
} from 'reactstrap'

import LookupModal from '../lookup-modal'


const ParamInput = ({
  param,
  prepareExplorerParams,
  sport,
  references,
  route
}) => {
  const [ value, setValue ] = useState('')
  const [ valid, setValid ] = useState(false)
  const [ invalid, setInvalid ] = useState(false)
  const [ showRefenceModal, toggleReferenceModal ] = useState(false)

  const toggleModal = () => toggleReferenceModal(!showRefenceModal)


  useEffect(() => {
    setValue('')
  }, [ route ])

  const reg = new RegExp(param.validation)

  useEffect(() => {
    if (!value) {
      setValid(false)
      setInvalid(false)
      prepareExplorerParams(param.paramName, value)

      return
    }

    if (reg.test(value)) {
      setValid(true)
      setInvalid(false)
      prepareExplorerParams(param.paramName, value)
    } else {
      setValid(false)
      setInvalid(true)
    }
  }, [ value ])

  const selectReference = id => {
    setValue(id)
    toggleReferenceModal(false)
  }

  let lookup = references[param.paramName]

  if (lookup) {
    lookup = lookup.filter(l => l.sport.indexOf(sport) >= 0)
  }

  return (
    <Row className="mar-10" breakpoints={{ 768: 'column' }}>
      <LookupModal
        isOpen={showRefenceModal}
        toggle={toggleModal}
        lookup={lookup}
        param={param.paramName}
        selectReference={selectReference}
      />
      <Column flex="3">
        <Row>
          { param.paramName }
          {' '}
          {
            isFirefox ?
              (
                <Button
                  className="rounded mar-left-5 pad-0"
                  style={{
                    height: '25px',
                    width: '12%',
                    backgroundColor: 'Transparent',
                    color: '#fff'
                  }}
                  id={param.paramName}
                  type="button"
                  onClick={e => e.target.focus()}
                >
                  ?
                </Button>
              )
              :
              (
                <Help
                  className="mar-top-5 mar-left-5 pointer"
                  style={{ height: '15px', width: '12%', outline: 'none' }}
                  id={param.paramName}
                />
              )
          }
          {
            lookup
              ? (
                <Search
                  onClick={toggleModal}
                  className="mar-top-5 mar-left-5 pointer"
                  style={{ height: '15px', width: '12%', outline: 'none' }}
                />
              )
              : null
          }
        </Row>
        <span className="sm-85 text-muted">{param.type}</span>
      </Column>

      <Column flex="5">
        <InputGroup size="md" className="rounded">
          <Input
            className="input-parameter white"
            value={value}
            placeholder={param.example}
            onChange={e => setValue(e.target.value)}
            onFocus={e => e.target.select()}
            valid={valid}
            invalid={invalid}
          />
        </InputGroup>
        {
          invalid ?
            param.validationError : null
        }
        <UncontrolledPopover
          trigger="focus"
          placement="top"
          className="pad-10"
          target={param.paramName}
        >
          <PopoverBody>{param.desc}</PopoverBody>
        </UncontrolledPopover>
      </Column>
    </Row>
  )
}

export default ParamInput
