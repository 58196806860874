import React from 'react'

import Stop from '@material-ui/icons/Stop'
import Check from '@material-ui/icons/Check'
import Pause from '@material-ui/icons/Pause'
import PlayArrow from '@material-ui/icons/PlayArrow'

import {
  Button,
  Card,
  CardBody,
  Col
} from 'reactstrap'

import {
  Column,
  Row
} from 'simple-flexbox'

const StartReplayButton = ({ replayStatus, initializeReplay }) => {
  const stoppedReplayStates = {
    stopped: "START REPLAY",
    stopping: "Stopping...",
    initializing: "Initializing..."
  }

  return (
    <Column flex="1" alignItems="center">
      <Button
        disabled={replayStatus !== "stopped"}
        onClick={replayStatus === "stopped" && initializeReplay}
        className="replay-button"
      >
        {replayStatus === 'stopped' ? <b>{stoppedReplayStates[replayStatus]}</b> : stoppedReplayStates[replayStatus]}
      </Button>
    </Column>
  )
}

const ReturnReplayStatusButton = ({
  replayStatus, initializeReplay, stopReplay, pauseReplay, resumeReplay
}) => {
  if (replayStatus === 'started' || replayStatus === 'paused') {
    return (
      <Column flex="1" className="text-center">
        <Row flex="1" justifyContent="center" alignItems="center">
          <Column className="w-25">
            {
              replayStatus === 'paused'
                ? (
                  <Button
                    className="mar-5"
                    outline
                    onClick={resumeReplay}
                    color="primary"
                    style={{ borderRadius: '8px' }}
                  >
                    Resume Replay
                    <PlayArrow />
                  </Button>
                )
                : (
                  <Button
                    className="mar-5"
                    outline
                    onClick={pauseReplay}
                    color="warning"
                    style={{ borderRadius: '8px' }}
                  >
                    Pause Replay
                    <Pause />
                  </Button>
                )
            }
          </Column>
          <Column className="w-25">
            <Button
              className="mar-5"
              outline
              onClick={stopReplay}
              color="danger"
              style={{ borderRadius: '8px' }}
            >
              Stop Replay
              {' '}
              <Stop />
            </Button>
          </Column>
        </Row>
      </Column>
    )
  }
  return <StartReplayButton replayStatus={replayStatus} initializeReplay={initializeReplay} />
}

const StartReplayCard = ({ selectedGame, updateReplayStatus, replayStatus }) => {
  if (!selectedGame) return null

  const pauseReplay = () => {
    updateReplayStatus('paused')
  }

  const resumeReplay = () => {
    updateReplayStatus('resume')
  }

  const initializeReplay = () => {
    updateReplayStatus('initializing')
  }

  const stopReplay = () => {
    updateReplayStatus('stopping')
  }

  return (
    <Card
      style={{
        borderRadius: '8px',
        backgroundColor: '#374150',
        color: '#fff',
        width: (replayStatus === 'started' || replayStatus === 'paused')
          ? '100%' : '100%',
        border: (replayStatus === 'started' || replayStatus === 'paused')
          ? '1px solid #57ca67' : '1px solid #fff'
      }}
    >
      <CardBody>
        <Row>
          <Column
            justifyContent="center"
            className={(replayStatus === 'started' || replayStatus === 'paused')
              ? 'replay-green' : 'text-muted'}
            style={{ fontSize: '24px' }}
          >
            {
              (replayStatus === 'started' || replayStatus === 'paused') ?
                <Check className="green" />
                : null
            }
          </Column>
          <ReturnReplayStatusButton 
            replayStatus={replayStatus} 
            initializeReplay={initializeReplay}
            stopReplay={stopReplay}
            pauseReplay={pauseReplay}
            resumeReplay={resumeReplay}
          />
        </Row>
      </CardBody>
    </Card>
  )
}

export default StartReplayCard
