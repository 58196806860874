import React, { useState } from 'react'
import {
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap'

import {
  Column,
  Row
} from 'simple-flexbox'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import copy from 'copy-to-clipboard'

const ReplayURL = ({ replayURL }) => {
  const [ popoverOpen, setPopoverOpen ] = useState(false)
  const [ copyIconColor, setCopyIconColor ] = useState('')
  const [ showCurl, toggleShowCurl ] = useState()

  const copyURL = () => {
    copy(showCurl ? `curl -X GET "${ replayURL }"` : replayURL)
    setCopyIconColor('green')
    setTimeout(() => {
      setCopyIconColor('')
    }, 2500)
  }

  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <div>
      <Row>
        <Column flex="16" className="text-left">
          <code>
            {
              showCurl
                ?
                `curl -X GET "${ replayURL }"`
                :
                replayURL
            }
          </code>
        </Column>

        <Column className="text-right">
          <span
            style={{
              cursor: 'pointer',
              backgroundColor: showCurl ? '#20a8d8' : null,
              borderRadius: '8px',
              paddingLeft: '5px',
              paddingRight: '5px',
              paddingTop: '2px',
              paddingBottom: '2px',
              border: !showCurl ? '1px solid #20a8d8' : null,
              color: !showCurl ? '#20a8d8' : '#fff'
            }}
            className="sm-80 btn"
            onClick={() => toggleShowCurl(!showCurl)}
            onKeyUp={() => {}}
          >
            cURL
          </span>
        </Column>

        <Column className="text-center">
          <FileCopyIcon
            className={`${ copyIconColor } pad-left-5 mar-left-5`}
            style={{ cursor: 'pointer', borderLeft: '1px solid #000' }}
            onClick={copyURL}
          />
        </Column>
        <Column className="text-center">
          <HelpOutlineIcon
            style={{cursor: 'pointer', borderLeft: '1px solid #000'}}
            id="Popover1"
            onClick={toggle}
            className="pad-left-5 mar-left-5"
          />
          <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
            <PopoverHeader>Replay URL</PopoverHeader>
            <PopoverBody className="pad-10">
              Use this URL as an endpoint to retrieve your inplay data. You can use
              the slider below to change the point in the game that you are at.
            </PopoverBody>
         </Popover>
        </Column>
      </Row>
    </div>
  )
}


export default ReplayURL
