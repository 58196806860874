import React from 'react'
import { AuthProvider } from './auth-context'
import { AccountProvider } from './account-context'
import ErrorBoundary from './error-boundary'

const AppProviders = ({ children }) => {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <AccountProvider>
          { children }
        </AccountProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default AppProviders
