import {
  authServerUrlBase,
  returnSpawnApiUrl
} from './urls'

const completeAPICall = (url, config, cb) => {
  fetch(url, config)
    .then(response => response.json()
      .then(res => {
        if (!res.status) {
          if (res.error) {
            return cb(res.error)
          }

          return cb('Error')
        }

        return cb(null)
      }))
    .catch(() => cb('Error'))
}

export const validateAccountStatus = cb => {
  const config = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  fetch(`${ returnSpawnApiUrl() }/account/validate`, config)
    .then(response => response.json()
      .then(res => {
        if (!res.status) {
          if (res.error) {
            return cb(res.error)
          }

          return cb('Error')
        }

        if (res.data.results && res.data.results[ 0 ].active) {
          return cb(null, true)
        }

        return cb(null, false)
      }))
    .catch(() => cb('Error'))
}


export const logoutClient = cb => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/logout`, config, cb)
}

// Login user
// requires Email & Password
export const loginClient = (user, cb) => {
  if (!user || !user.email || !user.password) {
    return cb('Error')
  }

  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      email: user.email,
      password: user.password
    }),
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/login`, config, cb)
}


export const registerClient = (user, cb) => {
  if (!user) {
    return cb('err')
  }

  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
      org: user.org
    }),
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/signup`, config, cb)
}


export const sendResetEmail = (email, cb) => {
  const config = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      email
    }),
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/password/forgot`, config, cb)
}


export const resetPWClient = (user, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      did: user.did,
      secret: user.secret,
      password: user.newPassword
    }),
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/password/set/reset`, config, cb)
}

// used when people are invited via core tool or org admin
export const setPWClient = (user, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      did: user.did,
      secret: user.secret,
      password: user.password
    }),
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/password/set/invited`, config, cb)
}

export const changePW = (pws, cb) => {
  const config = {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      old_password: pws.oldPassword,
      new_password: pws.newPassword
    }),
    json: true
  }

  completeAPICall(`${ authServerUrlBase() }/password/set/update`, config, cb)
}
